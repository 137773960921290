<!-- 各类动作提示 -->
<template>
  <div class="ActionTip">
    <!-- 确定事件 -->
    <div class="ConfirmEvent">
      <!-- 有循环的 -->
      <div
        class="LoopWrap"
        v-if="TipType.type == 'LoopEvent'">
        <el-dialog
          class="PopupBox"
          title="Delete recurring booking"
          :visible.sync="dialogVisible"
          width="26%"
          top="25vh">
          <div
            class="ThisBooking"
            v-for="(item, index) in LoopOptions"
            :key="index">
            <div class="LeftSwitch">
              <div
                class="false"
                @click="LoopSelete = index">
                <div
                  class="true"
                  v-if="LoopSelete == index"></div>
              </div>
            </div>
            <div class="text">{{ item }}</div>
          </div>
          <span
            slot="footer"
            class="dialog-footer">
            <el-button @click="dialogVisible = false">
              {{ $t("lang.cancel") }}</el-button>
            <el-button
              type="primary"
              @click="ConfirmEvent()">{{ $t("lang.Ok") }}
            </el-button>
          </span>
        </el-dialog>
      </div>
      <!-- 没有循环的 -->
      <div
        class="noLoopWrap"
        v-if="TipType.type == 'noLoopEvent'">
        <el-dialog
          class="PopupBox"
          :visible.sync="dialogVisible"
          width="26%"
          top="30vh">
          <div class="IsBooking">Are you sure want to delete this booking?</div>
          <span
            slot="footer"
            class="dialog-footer">
            <el-button @click="dialogVisible = false">
              {{ $t("lang.cancel") }}</el-button>
            <el-button
              type="primary"
              @click="ConfirmEvent()">{{ $t("lang.Ok") }}
            </el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    TipType: {
      type: Object,
      default: () => {
        return {
          type: 'noLoopEvent', // LoopEvent 循环事件  noLoopEvent 普通事件
          Operation: 'delete' // delete 删除事件  update 更新事件
        }
      }
    }
  },
  data () {
    return {
      dialogVisible: true,
      //  选择循环事件
      LoopSelete: 0,
      // 选择
      LoopOptions: [
        'This booking',
        'This and following bookings',
        'All bookings'
      ]
    }
  },
  created () { },
  watch: {
    dialogVisible (value) {
      if (!value) {
        this.$emit('CloseDialog')
      }
    }
  },
  mounted () { },
  methods: {
    // 确定什么操作
    ConfirmEvent () {
      // 判断
      if (this.TipType.Operation == 'delete') {
        this.$emit('deleteLoopEvent', this.LoopSelete)
      } else if (this.TipType.Operation == 'update') {
        this.$emit('updateLoopEvent', this.LoopSelete)
      }
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="less">
.ActionTip {
  .ConfirmEvent {
    .LoopWrap {
      .LeftSwitch {
        .false {
          width: 20px;
          height: 20px;
          border: 1px solid #33ab4f;
          border-radius: 50%;
        }
        .true {
          width: 10px;
          height: 10px;
          background: #33ab4f;
          margin: 4px auto;
          border-radius: 50%;
        }
      }
      .ThisBooking {
        display: flex;
        margin-bottom: 17px;
        .text {
          margin-left: 10px;
        }
      }
      .ThisBooking:nth-child(3) {
        margin-bottom: 0px;
      }
    }
    .noLoopWrap {
      ::v-deep .el-dialog__header {
        height: 56px;
        text-align: left;
        border-bottom: none;
      }
      .IsBooking {
        height: 19px;
        font-size: 16px;
        color: #dfdfdf;
        line-height: 19px;
        text-align: left;
      }
    }
  }
  .PopupBox {
    ::v-deep .el-dialog__header {
      height: 86px;
      text-align: left;
      border-bottom: none;
      .el-dialog__title {
        height: 24px;
        margin: 38px 0 24px;
        line-height: 24px;
      }
      .el-dialog__headerbtn {
        top: 26px;
        height: 14px;
        line-height: 14px;
        font-size: 14px;
        .el-dialog__close {
          font-size: 14px;
        }
      }
    }
    ::v-deep .el-dialog__body {
      padding: 0 20px 56px;
    }
    ::v-deep .el-dialog__footer {
      padding: 0 0 20px;
    }
  }
}
</style>
