<template>
  <div
    id="Booking"
    @click="hideEle">
    <div class="header">
      <div class="f_left fl">
        <div class="table_view">
          <p
            class="fl pointer"
            :title="$t('lang.tableView')"
            :class="{ active: page == 'table' }"
            @click="switchPage('table')">
            <i class="iconfont">&#xe70e;</i>
          </p>
          <p
            class="fl pointer"
            :title="$t('lang.calendarView')"
            :class="{ active: page == 'calendar' }"
            @click="switchPage('calendar')">
            <i class="iconfont">&#xe61a;</i>
          </p>
        </div>
      </div>
      <div
        class="f_left booking_execute pr"
        v-if="runningList.length > 0">
        <i class="f_left circle vertical_center"></i>
        <span
          class="f_left"
          style="color: #999; font-size: 10px; margin-right: 16px">{{ $t("lang.running") }}：</span>
        <div
          class="f_left booking_exe_info pr pointer"
          @click="showOtherRunn">
          <span
            class="pointer ellipsis"
            style="display: inline-block; width: 100%">
            {{ preview.runningBook.tname }}, {{ preview.runningBook.rname }}, {{ getRunningBokTime( preview.runningBook.startTime, preview.runningBook.endTime ) }}, {{ preview.runningBook.title }}
          </span>
          <span
            class="more pointer"
            v-if="runningList.length > 1"></span>
          <ul
            class="runnings_info ellipsis"
            v-if="this.isShow.showOtherRunn">
            <li
              v-for="(itm, idx) in runningList"
              :key="idx"
              @click="selRunningBok(itm.id)">
              {{ itm.tname }}, {{ itm.rname }}, {{ getRunningBokTime(itm.startTime, itm.endTime) }}, {{ itm.title }}
            </li>
          </ul>
        </div>
        <button
          class="f_left"
          @click="startLive">
          {{ $t("lang.liveVideo") }}
        </button>
      </div>
      <div class="f_right new_booking">
        <p
          class="copy f_left pointer"
          :class="{ dis_copy: !copyFlag }"
          @click="toCopyEvent">
          {{ $t("lang.copy") }}
        </p>
        <el-button
          type="text"
          @click="openCreateBox">{{ $t("lang.create") }}
        </el-button>
      </div>
    </div>
    <div class="content">
      <table-view
        @copyIndex="copyIndex"
        @switchTab="switchTab"
        @searchBookingList="searchBookingList"
        v-if="page == 'table'"
        :clickIndex="clickIndex"
        :runningEvent="runningEvent"
        :pendingEvent="pendingEvent">
      </table-view>
      <calendar
        v-if="page == 'calendar'"
        :bookmark="bookmark"></calendar>
    </div>
    <create-box
      :visible.sync="visible"
      @closeBox="cancelCreate"
      :event="event"
      v-if="visible"></create-box>
    <preview
      @closePreview="closePreview"
      :audio="audio"
      :preview="preview"
      v-if="isShow.preview"
      :liveInfo="liveInfo"></preview>
  </div>
</template>
<script>
import tableView from './tableViewModule'
import calendar from './calendarModule'
import preview from '../Booking/previewModule'
import Outil from '../../assets/js/utils.js'
import { mapState } from 'vuex'
import createBox from './createBox'
import { bookingAnalyze } from '@/assets/js/googleAnalyze/booking.js'
export default {
  data () {
    return {
      visible: false,
      copyFlag: true,
      runningList: [],
      page: 'table',
      pendingEvent: false,
      audio: {
        left: 0,
        right: 0
      },
      event: {}, // 创建/编辑框内的数据
      copyEvent: {},
      sourceList: [], // 源列表
      RList: [], // R列表
      isShowHeight: false,
      isShow: {
        sourceList: false,
        RList: false,
        preview: false,
        showOtherRunn: false
      },
      runningEvent: 0,
      preview: {
        errorRate8s: 0,
        line8s: 0,
        errorRate1s: 0,
        quality1s: 0,
        quality: 0,
        currentTDelay: 0,
        currentTBitrate: 0,
        canSetDelay: 'lang.Fixed',
        currentTVideoMode: 'lang.VBR',
        tInput: 'ios',
        rFormat: 'NTSC',
        tFormat: '720p59.94',
        bat1val: 'N/A',
        bat2val: 'N/A',
        btnTitle: 'lang.stop',
        runningBook: {
          tname: 'webteam_114',
          rname: 'ava_r',
          title: '',
          startTime: '',
          endTime: '',
          rpeerid: ''
        },
        sourceItem: {
          peerId: ''
        }
      },
      liveInfo: {
        ReceiverData: {},
        CurrentRInfo: {},
        SetInfo: {}
      },
      clickIndex: 0,
      bookmark: [],
      selectedRunBookindex: 0,
      selectedRunEventId: null,
      pickerDateBeg: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return (
            time.getTime() <
            new Date(
              date.year + '-' + date.month + '-' + date.day + ' 00:00:00'
            ).getTime()
          )
        }
      },
      pickerDateEnd: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return (
            time.getTime() <
            new Date(
              date.year + '-' + date.month + '-' + date.day + ' 00:00:00'
            ).getTime()
          )
        }
      }
    }
  },
  components: {
    tableView,
    calendar,
    preview,
    createBox
  },
  created () {
    this.getBookmarkAll()
  },
  mounted () {
    this.sendRunSocket()
  },
  computed: {
    ...mapState({
      State: (state) => state,
      pageRequest: (state) => state.websocket.pageRequest,
      pageRWebsocket: (state) => state.websocket.pageRWebsocket
    })
  },
  methods: {
    // tableview中切换tab
    switchTab (val) {
      this.copyFlag = val
    },
    // 发送获取running list的请求和更新running bookings
    sendRunSocket () {
      let listEventParam = {
        type: 'listEventRunning',
        module: 'listEventRunning',
        data: ''
      }
      let existPendingEvent = {
        type: 'existPendingEvent',
        module: 'existPendingEvent',
        data: ''
      }
      this.sendWebSocket(listEventParam)
      this.sendWebSocket(existPendingEvent)
    },
    sendWebSocket (param) {
      this.pageRequest.send({
        message: param,
        time: 3000,
        key: param.type,
        success: (data) => {
          var result = JSON.parse(data.result.runningEvent)
          if (typeof result == 'object') this.runningEvent = result.length
          this[`handle${param.type[0].toUpperCase()}${param.type.slice(1)}`](result)
        }
      })
    },
    // 处理颜色
    handleExistPendingEvent (result) {
      this.pendingEvent = result
    },
    // 处理running list
    handleListEventRunning (result) {
      this.runningList = result
      if (result.length == 0) {
        this.closePreview() // 关闭弹窗
        return
      }
      if (!this.selectedRunEventId) {
        this.selectedRunEventId = this.runningList[0].id
      }
      this.runningList.map((v, i) => {
        if (v.id == this.selectedRunEventId) {
          this.selectedRunBookindex = i
        }
      })
      if (!this.runningList[this.selectedRunBookindex]) {
        this.selectedRunBookindex = 0
        this.selectedRunEventId = this.runningList[0] && this.runningList[0].id
      }
      this.preview.runningBook = this.runningList[this.selectedRunBookindex]
      this.preview.sourceItem.peerId = this.runningList[
        this.selectedRunBookindex
      ].tpeerid
    },
    // 选择正在running的booking
    selRunningBok (id) {
      this.selectedRunEventId = id
      this.runningList.map((v, i) => {
        if (v.id == id) {
          this.preview.runningBook = v
          this.preview.sourceItem.peerId = v.tpeerid
        }
      })
    },
    // 获取running booking的显示时间
    getRunningBokTime (startTime, endTime) {
      var startDate = Outil.getDateTime(new Date(startTime))
      var endDate = Outil.getDateTime(new Date(endTime))
      if (
        startDate.year == endDate.year &&
        startDate.month == endDate.month &&
        startDate.day == endDate.day
      ) {
        return (
          startDate.year +
          '-' +
          startDate.month +
          '-' +
          startDate.day +
          ' ' +
          startDate.hour +
          ':' +
          startDate.min +
          '-' +
          endDate.hour +
          ':' +
          endDate.min
        )
      } else {
        return (
          startDate.year +
          '-' +
          startDate.month +
          '-' +
          startDate.day +
          ' ' +
          startDate.hour +
          ':' +
          startDate.min +
          '-' +
          endDate.year +
          '-' +
          endDate.month +
          '-' +
          endDate.day +
          ' ' +
          endDate.hour +
          ':' +
          endDate.min
        )
      }
    },
    // 打开创建或复制booking弹窗
    openCreateBox () {
      bookingAnalyze('bookingCreateBoxOpen', '/bk')
      this.event.dialogTitle = 'TranscriberBooking'
      this.event.editFlag = false
      this.event.copyFlag = false
      this.visible = true
    },
    // 是否显示running列表
    showOtherRunn () {
      this.isShow.showOtherRunn = !this.isShow.showOtherRunn
    },
    // 切换页面
    switchPage (page) {
      bookingAnalyze(`booking${page.slice(0, 1).toUpperCase()}${page.slice(1)}Open`, '/bk')
      this.page = page
      this.copyFlag = page != 'calendar'
    },
    // 阻止默认冒泡
    prevent (e) {
      e.stopPropagation()
    },
    // 隐藏创建窗口
    cancelCreate (value) {
      this.event = {}
      this.visible = value
    },
    // 清空创建表格
    clearEvent () {
      this.event.title = ''
      this.event.reporter = ''
      this.event.description = ''
      this.event.startTime = ''
      this.event.stopTime = ''
      this.event.source = ''
      this.event.destination = ''
      this.event.delay = ''
      this.event.bit = ''
      this.event.color = 'rgb(15, 190, 113)'
    },
    // 接收需复制的事件
    copyIndex (event) {
      this.copyEvent = event
    },
    // 复制预约事件
    toCopyEvent () {
      bookingAnalyze('bookingDuplicateBoxOpen', '/bk')
      if (!this.copyFlag) return
      this.clearEvent()
      if (!this.copyEvent.title) {
        this.$message.error(this.$t('lang.selectEventFirst'))
        return
      }
      this.copyEvent.duration = this.copyEvent.endTime ? `${Math.floor((this.copyEvent.endTime - this.copyEvent.startTime) / 60000)}min` : undefined
      this.event = JSON.parse(JSON.stringify(this.copyEvent))
      this.event.dialogTitle = 'TranscriberBooking'
      this.event.editFlag = true
      this.event.copyFlag = true
      this.visible = true
    },
    // 隐藏元素
    hideEle () {
      this.clickIndex++
      for (var key in this.isShow.calendar) {
        this.isShow.calendar[key] = false
      }
    },
    // 关闭预览页面
    closePreview () {
      this.isShow.preview = false
      this.isShow.mask = false
      this.preview.btnTitle = 'lang.live'
      if (this.$createWebsocket.pageRWebsocket && this.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', [
          'VolumeChart',
          'receiverData',
          'currentRInfo',
          'setInfo'
        ]) // 停止websocket
      }
    },
    // 开始直播
    startLive () {
      bookingAnalyze('bookingLiveEventPlayOpen', '/bk')
      this.isShow.mask = true
      this.getLiveEventData('VolumeChart', '2152867840', '200', 200) // 获取音量信息
      this.getLiveEventData('ReceiverData', '2152857600', '101', 1000) // 获取err and quality信息
      this.getLiveEventData('CurrentRInfo', '2152857600', '102', 1000) // 获取type and format信息
      this.getLiveEventData('SetInfo', '2152866048', '101', 1000) // 获取bitrate and delay信息
      this.isShow.preview = true
    },
    // 获取live信息
    getLiveEventData (type, categoryId, operationType, time) {
      let typeVal = ''
      if (type != 'VolumeChart') {
        typeVal = `${type[0].toLowerCase()}${type.slice(1, type.length)}`
      }
      this.pageRWebsocket.send({
        message: {
          categoryId: categoryId,
          operationType: operationType,
          rid: this.preview.runningBook.rpeerid
        },
        time: time || 5000,
        key: typeVal || type,
        success: (res) => {
          let result = JSON.parse(res.data)
          switch (type) {
            case 'ReceiverData':
              this.preview.errorRate8s = Math.ceil(result.ErrorRateLastNSec)
              this.preview.errorRate1s = result.ErrorRateLastSec
              this.preview.quality1s = result.ReSendRateLastSec
              this.preview.line8s = result.ReSendRateLastNSec
              this.preview.quality = result.QualityMeasureOnGUI
              // 更改vuex中的对象
              this.State.ReceiverInfoObj = result
              break
            case 'CurrentRInfo': {
              let BAT1VAL = result.CurrentTPackBatteryRaw.BAT1VAL
              let BAT2VAL = result.CurrentTPackBatteryRaw.BAT2VAL
              this.preview.bat1val = `${BAT1VAL} ${BAT1VAL.toLowerCase() == 'n/a' ? '' : '%'}`
              this.preview.bat2val = `${BAT2VAL} ${BAT2VAL.toLowerCase() == 'n/a' ? '' : '%'}`
              this.preview.tInput = result.CurrentTInputType.ReadableName
              this.preview.tFormat = result.CurrentTFormat.ReadableName
              this.preview.rFormat = result.CurrentOutputFormat2.ReadableName
              // 更改vuex中的对象
              this.State.GetKernelStateObj = result
              break
            }
            case 'SetInfo':
              this.preview.currentTDelay = result.CurrentSetting.Delay / 1000
              this.preview.currentTBitrate = Number(result.CurrentSetting.Bitrate)
              this.preview.canSetDelay = result.CurrentTCanSetDelay ? 'lang.Dynamic' : 'lang.Fixed'
              this.preview.currentTVideoMode = Number(result.CurrentTVideoMode) ? 'CBR' : 'VBR'
              break
            case 'VolumeChart':
              this.audio.left = Math.round(((result.LeftDb - -40) / 40) * 166)
              this.audio.right = Math.round(
                ((result.RightDb - -40) / 40) * 166
              )
              break
          }
        }
      })
    },
    // 获取bookmark
    getBookmarkAll () {
      Outil.axios('get', '/ccp/tvucc-user/bookmark/getAllChildBookmarkTree', '', (data) => {
        data.push({
          bookmarkName: 'lang.unClassified',
          children: [],
          id: 'unclassified'
        })
        this.bookmark = data
      }
      )
    },
    searchBookingList () {
      this.copyEvent = {}
    }
  },
  beforeDestroy () {
    if (this.$createWebsocket.pageRequest && this.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'listEventRunning') // 停止websocket
    }
    if (this.$createWebsocket.pageRWebsocket && this.pageRWebsocket) {
      this.$createWebsocket.pageRWebsocket('stop', [
        'VolumeChart',
        'receiverData',
        'currentRInfo',
        'setInfo'
      ]) // 停止websocket
    }
  }
}
</script>
<style lang="less" scoped>
#Booking {
  height: 100%;
  background: #444;
  input,
  textarea {
    outline: none;
  }
  p {
    margin-bottom: 0;
  }
  .header {
    height: 60px;
    background: #252525;
    .f_left,
    .f_right {
      height: 100%;
    }
    .table_view {
      height: 100%;
      text-align: left;
      margin-left: 34px;
      width: 274px;
      font-size: 24px;
      p {
        height: 100%;
        padding-top: 18px;
        text-align: center;
        width: 100px;
      }
      p:first-child {
        margin-right: 40px;
      }
      p.active {
        border-bottom: 4px solid #33ab4f;
        color: #fff;
      }
      .iconfont {
        font-size: 20px;
      }
    }
    .booking_execute {
      font-size: 10px;
      line-height: 60px;
      padding-left: 15px;
      .circle {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #33ab4f;
        left: 0;
      }
      .booking_exe_info {
        width: 520px;
        text-align: left;
        .more {
          position: absolute;
          right: 0;
          top: 55%;
          transform: translateY(-50%);
          border-width: 8px;
        }
      }
      button:hover {
        background-color: rgba(216, 216, 216, 0.2);
      }
      button {
        width: 100px;
        height: 26px;
        background: rgba(216, 216, 216, 0);
        line-height: 26px;
        border-radius: 4px;
        border: 1px solid #757575;
        text-align: center;
        margin-top: 17px;
        margin-left: 20px;
        cursor: pointer;
      }
      .runnings_info {
        width: 100%;
        position: absolute;
        left: -6px;
        top: 40px;
        background: #666;
        border-radius: 4px;
        z-index: 100;
        li {
          height: 25px;
          line-height: 25px;
          padding-left: 10px;
          padding-right: 5px;
          cursor: pointer;
        }
        li:hover {
          background: rgba(0, 208, 105, 0.2);
        }
      }
    }
    .new_booking {
      margin-top: 12px;
      margin-right: 80px;
      p {
        width: 100px;
        height: 36px;
        line-height: 36px;
        font-size: 16px;
      }
      .copy {
        color: #33ab4f;
        border: 1px solid #33ab4f;
        border-radius: 6px;
        background-color: rgba(49, 171, 79, 0);
        margin-right: 14px;
      }
      .dis_copy.copy {
        background: linear-gradient(180deg, #575757, #444);
        border-color: #444;
        color: #999;
      }
      .copy:hover {
        background-color: rgba(49, 171, 79, 0.2);
      }
      .el-button.el-button--text {
        background: #33ab4f;
        color: #fff;
        // padding: 6px 12px;
        font-size: 16px;
        height: 36px;
        line-height: 36px;
        padding: 0 25px;
      }
      // .create_btn {
      //     background-color: rgba(49,171,79,1);
      //     color: #FFF;
      //     border-radius: 6px;
      //     border: 1px solid #33ab4f;
      // }
      // .create_btn:hover {
      //     background-color: rgba(49,171,79,0.8);
      // }
    }
  }
  .content {
    background: #444;
    height: calc(100% - 60px);
  }
  .create {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #666;
    opacity: 0.65;
    z-index: 100;
  }
}
::v-deep .el-popper[x-placement^='bottom'] .popper__arrow:after {
  border-bottom-color: #fff;
}
::v-deep .table_view .filter .el-input__inner {
  cursor: pointer;
}
::v-deep .el-input.el-input--small .el-input__inner {
  background: #fff;
  border-color: #e4e4e4;
}
::v-deep
  .el-date-picker__editor-wrap
  .el-input.el-input--small
  .el-input__inner {
  color: #000;
}
::v-deep .el-message {
  z-index: 30000 !important;
}
@media (min-width: 500px) and (max-width: 759px) {
  #Booking {
    .content {
      clear: both;
    }
    .header {
      .new_booking {
        margin-right: 10px;
        padding-top: 16px;
        margin-top: 0px;
      }
      .table_view {
        margin-left: 10px;
        width: 189px;
        p {
          width: 65px;
          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  #Booking .header .table_view {
    margin-left: -5px;
  }
}
</style>
