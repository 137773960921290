<template>
  <div class="CreateBox">
    <el-dialog
      :title="$t(`lang.${event.dialogTitle}`)"
      :visible.sync="visible"
      width="700px"
      class="booking_create_box"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <el-form
        :model="dialogValObj"
        status-icon
        :rules="rules"
        ref="event"
        label-width="127px"
        class="create_booking_box">
        <div class="form_content">
          <el-form-item
            :label="$t('lang.scheduleTitle')"
            prop="title"
            class="TitleandColor">
            <el-input
              type="text"
              v-model="dialogValObj.title"
              autocomplete="off"
              maxlength="32"></el-input>
            <!-- 颜色选择 -->
            <div
              class="color_box f_right pr pointer"
              @click.stop="showColorOpt = !showColorOpt">
              <div
                class="color f-left"
                :class="ColorTransition(dialogValObj.color)"></div>
              <i
                class="el-icon-caret-bottom"
                :class="{ top: showColorOpt }"></i>
              <div
                class="color_opt triangle"
                v-if="showColorOpt">
                <span class="f_left">{{ $t("lang.color") }}</span>
                <ul class="f_left">
                  <li
                    v-for="(item, index) in colorArr"
                    :key="index"
                    :class="['f_left', item.name]"
                    @click="chooseColor(item)"></li>
                </ul>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            :label="`${$t('lang.time')}`"
            prop="duration">
            <el-date-picker
              v-model="dialogValObj.startTime"
              popper-class="pick_date_timebk"
              @change="changeStartTime"
              :picker-options="pickerDateBeg"
              format="MM-dd-yyyy HH:mm:ss"
              type="datetime"
              class="create_box_time">
            </el-date-picker>
            <span
              class="f_left"
              style="margin: 0 10px">{{ $t("lang.to") }}
            </span>
            <el-date-picker
              v-model="dialogValObj.endTime"
              popper-class="pick_date_timebk"
              @change="changeEndTime"
              :picker-options="pickerDateEnd"
              format="MM-dd-yyyy HH:mm:ss"
              type="datetime"
              class="create_box_time stop_time">
            </el-date-picker>
            <span
              class="f_left"
              style="margin-left: 10px">{{ $t("lang.duration") }}：</span>
            <span class="f_left duration">{{ dialogValObj.duration }}</span>
          </el-form-item>

          <el-form-item :label="$t('lang.Repeat')">
            <el-select
              class="LoopSelect"
              v-model="LoopSelete"
              @change="SeleteLoopChang"
              :popper-append-to-body="false"
              @focus="HideTimeSelete()">
              <el-option
                v-for="item in LoopOptions"
                :key="item.type"
                :label="item.label"
                :value="item.type">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="LoopSelete != 'noloop'"
            prop="cycleEndTime">
            <div class="SeleteEndTime">
              <p>
                <span>*</span>{{ $t("lang.endRepeat") }}</p>
              <el-date-picker
                popper-class="pick_date_timebk"
                format="MM-dd-yyyy"
                value-format="timestamp"
                v-model="dialogValObj.cycleEndTime"
                type="date"
                :picker-options="pickerRepeatEndTime"
                class="RepeatEndTime create_box_time">
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item
            :label="$t('lang.deviceSourceScte')"
            prop="tpeerid">
            <div class="deviceSource">SDI</div>
          </el-form-item>
          <el-form-item
            :label="`${$t('lang.Channel')}`"
            prop="rpeerid">
            <i
              class="arrow-icon"
              @click="changeVisibleR('handle')"
              :class="[
                visibleStatusR ? 'el-icon-arrow-up' : 'el-icon-arrow-top',
              ]"></i>
            <el-select
              filterable
              placeholder=""
              v-model="dialogValObj.rpeerid"
              v-scroll="bindRScroll"
              popper-class="booking_create_box_channel"
              ref="r"
              @focus="getRList"
              @change="changeR"
              remote
              :remote-method="searchR"
              @blur="initSearchCondi"
              @visible-change="changeVisibleR"
              :popper-append-to-body="false"
              :disabled="!allowSelectR">
              <el-option
                v-for="item in RList"
                :key="item.peerId"
                :label="item.name"
                :value="item.peerId"
                :title="item.name">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="btn_box">
          <el-form-item>
            <el-button
              type="primary"
              v-repeat-click="{ event: createBooking, params: ['event'] }">{{ $t("lang.save") }}</el-button>
            <el-button @click="cancel('event')">{{ $t("lang.cancel") }}
            </el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>

    <!-- 各种操作的提示框 -->
    <div class="TipFrame">
      <action-tip
        v-if="TipIsShow"
        :TipType="TipType"
        @CloseDialog="TipIsShow = false"
        @updateLoopEvent="updateLoopEvent"></action-tip>
    </div>
  </div>
</template>

<script>
import Outil from '../../assets/js/utils.js'
import Bus from '@/assets/js/vueBus.js'
import { bookingAnalyze } from '@/assets/js/googleAnalyze/booking.js'
import toTips from '@/assets/js/toTipsObj'
import ActionTip from './ActionTip.vue'
import filter from 'lodash.filter'
import findIndex from 'lodash.findindex'
export default {
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    event: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      // 颜色列表
      colorArr: [
        {
          name: 'green',
          value: 'rgb(15, 190, 113)'
        },
        {
          name: 'skyblue',
          value: 'rgb(70, 201, 225)'
        },
        {
          name: 'blue',
          value: 'rgb(47, 141, 244)'
        },
        {
          name: 'orange',
          value: 'rgb(255, 197, 115)'
        },
        {
          name: 'pink',
          value: 'rgb(255, 106, 146)'
        },
        {
          name: 'yellow',
          value: 'rgb(255, 192, 0)'
        }
      ],
      // 颜色选择
      showColorOpt: false,
      // 弹出框是否显示
      TipIsShow: false,
      // 提示类型
      TipType: {
        type: 'noLoopEvent', // LoopEvent 循环事件  noLoopEvent 普通事件
        Operation: 'delete' // delete 删除事件  update 更新事件
      },
      dialogValObj: {
        id: '',
        title: '',
        color: '',
        rpeerid: '', // rid
        tpeerid: '', // sourceid
        startTime: '',
        endTime: '',
        duration: '', // 事件长度
        cycle: false, // 是否循环
        cycleRule: 1, // 循环规则 daily：按日循环，weekly:按每周几循环，everyWeekday：每周工作日循环，everyWeekend:每周周末循环
        dayOfWeek: 1, // 当cycleRule为weekly时，需要传入是周几。1-7分别代表周一到周日
        cycleEndTime: null // 循环结束时间
      },
      // 对于循环事件的选择
      LoopSelete: 'noloop',
      // 循环事件的选项
      LoopOptions: [
        {
          type: 'noloop',
          label: 'Does not repeat'
        },
        {
          type: 1,
          label: 'Daily'
        },
        {
          type: 2,
          label: 'Weekly on Tuesday'
        },
        {
          type: 3,
          label: 'Every weekday (Monday to Friday)'
        },
        {
          type: 4,
          label: 'Every weekend (Saturday and Sunday)'
        }
      ],
      // 周几
      weekTitleList: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      bindRScroll: {
        onScroll: this.handleRScroll,
        distance: 10,
        directive: '.el-select-dropdown__wrap.el-scrollbar__wrap'
      },
      selectedR: {
        name: ''
      },
      sourceType: '',
      disabled: {
        delay: true,
        bitrate: true
      },
      sourceParam: {
        pageNum: 1,
        pageSize: 15,
        condition: '',
        living: true,
        online: true,
        offline: true,
        pack: true,
        anywhere: true,
        gridPack: true,
        gridSdi: true,
        ext: true,
        bookmardIds: 'all',
        unClassified: true,
        token: true,
        globalGrid: true,
        gridViaToken: true,
        onlineFirst: true,
        liveFirst: true,
        battery: true,
        bookmarks: true,
        scte: false,
        booking: true,
        pn: 'bk',
        tokenRegardlessOfUser: false,
        noCamera: true
      },
      sourceTotalPag: 1,
      rParam: {
        sid: '', // UGC情况下该参数为空
        condition: '',
        auto: 0, // null全部，0自动，1手动
        pageNum: 1,
        pageSize: 15,
        booking: true,
        userGroupId: ''
      },
      rTotalPag: 1,
      createStopTime: '',
      dialogVisible: false,
      RList: [],
      addFlag: true,
      pickerDateBeg: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return (
            time.getTime() <
            new Date(
              date.year + '-' + date.month + '-' + date.day + ' 00:00:00'
            ).getTime()
          )
        }
      },
      pickerDateEnd: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return (
            time.getTime() <
            new Date(
              date.year + '-' + date.month + '-' + date.day + ' 00:00:00'
            ).getTime()
          )
        }
      },
      pickerRepeatEndTime: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return (
            time.getTime() <
            new Date(
              date.year + '-' + date.month + '-' + date.day + ' 00:00:00'
            ).getTime()
          )
        }
      },
      visibleStatusS: false,
      autoClickFlagS: false,
      visibleStatusR: false,
      autoClickFlagR: false,
      groupList: [],
      allowSelectR: true,
      groupObj: {
        pageSize: 10,
        pageNum: 1
      },
      groupTotalPag: 1
    }
  },
  components: {
    ActionTip
  },
  created () {
    /*
     * 编辑时选中的nickName的回显，计算duration
     * 根据是新增还是编辑进行初始化赋值
     */
    if (this.event.editFlag) {
      this.dialogValObj = JSON.parse(JSON.stringify(this.event))
      this.dialogValObj.cycleEndTime = this.dialogValObj.cycleEndTime
        ? this.dialogValObj.cycleEndTime
        : null
      if (this.event.copyFlag) {
        this.dialogValObj.cycle = false
      }
      this.getEventWeekday(this.dialogValObj.startTime)
      this.calcDuration(this.dialogValObj.startTime, this.dialogValObj.endTime)
      this.getRList()
    }
  },
  mounted () {
    this.$nextTick(() => {
      // 为了解决点击时间选择后，不关闭时间框就去点击source下拉出现的问题
      const nodeList = document.querySelectorAll('.create_box_time')
      nodeList.forEach((v) => {
        v.addEventListener('focus', this.elClick, true)
      })
    })
  },
  watch: {
    'dialogValObj.startTime' (value) {
      this.getEventWeekday(value)
      this.pickerRepeatEndTime.disabledDate = this.RepeatDisabledDate
    }
  },
  computed: {
    rules () {
      return {
        title: [
          {
            required: true,
            message: this.$t('lang.titleEmpty'),
            trigger: 'blur'
          }
        ],
        duration: [
          {
            required: true,
            message: this.$t('lang.durEmpty'),
            trigger: 'blur'
          }
        ],
        rpeerid: [
          {
            required: true,
            message: this.$t('lang.rEmpty'),
            trigger: 'change'
          }
        ],
        cycleEndTime: [
          {
            required: true,
            message: this.$t('lang.EndtimeEmpty'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    // 时间禁止选择
    RepeatDisabledDate (time) {
      return time.getTime() < this.dialogValObj.startTime
    },
    // 转换颜色
    ColorTransition (color) {
      let colorName = this.colorArr.filter((item) => {
        return item.value == color
      })
      return colorName[0] ? colorName[0].name : 'green'
    },
    // 选择颜色
    chooseColor (item) {
      this.dialogValObj.color = item.value
    },
    // 选择循环变化时
    SeleteLoopChang (item) {
      if (item == 'noloop') {
        this.dialogValObj.cycle = false
      } else {
        this.dialogValObj.cycle = true
        this.dialogValObj.cycleRule = item
        this.$nextTick(() => {
          // 为了解决点击时间选择后，不关闭时间框就去点击source下拉出现的问题
          const nodeList = document.querySelectorAll('.SeleteEndTime .RepeatEndTime')
          nodeList[0].addEventListener('focus', this.elClick, true)
        })
      }
    },
    // 获取事件在周几
    getEventWeekday (startTime) {
      let weekIndex =
        typeof startTime == 'object'
          ? startTime.getDay()
          : new Date(startTime).getDay()
      if (weekIndex == 0) {
        weekIndex = 7
      }
      //  设置选项
      this.$set(this.LoopOptions, 2, {
        type: 2,
        label: `Weekly on ${this.weekTitleList[weekIndex - 1]}`
      })
      this.dialogValObj.dayOfWeek = weekIndex
      if (this.dialogValObj.cycle) {
        if (this.dialogValObj.cycleRule) {
          this.LoopSelete = this.dialogValObj.cycleRule
        }
      } else {
        this.LoopSelete = 'noloop'
      }
    },
    elClick () {
      $('.booking_create_box .el-dialog').click()
    },
    // R滚动处理事件
    handleRScroll () {
      if (this.rTotalPag < this.rParam.pageNum) {
        return
      }
      this.rParam.pageNum++
      this.getRList()
    },
    // 关闭弹窗
    handleClose () {
      this.$refs.event.resetFields()
      this.sourceParam.pageNum = 0
      this.$emit('closeBox', false)
    },
    // 切换r
    changeVisibleR (flag) {
      if (flag === 'handle') {
        if (!this.autoClickFlagR && !this.visibleStatusR) {
          this.$refs.r.focus()
        }
        this.autoClickFlagR = false
      } else {
        this.autoClickFlagR = true
        this.visibleStatusR = flag
        if (flag) {
          this.$refs.r.blur()
          this.$refs.r.focus()
        }
      }
    },
    // 创建预约事件
    createBooking (formName) {
      bookingAnalyze('bookingCreateEventSave', '/bk')
      let verifyArr = ['title', 'duration']
      verifyArr.push('cycleEndTime')
      // 是否验证rpeerid
      verifyArr.push('rpeerid')
      let errors = []
      this.$refs[formName].validateField(verifyArr, (error) => {
        if (error) {
          errors.push(error)
        }
      })
      if (errors.length > 0) {
        return false
      } else {
        this.sendReqCreateBooking(formName)
        return false
      }
    },
    // 发送add或者updata请求
    sendReqCreateBooking (formName) {
      this.dialogValObj.title = this.dialogValObj.title.trim()
      this.dialogValObj.startTime = typeof this.dialogValObj.startTime == 'object' ? this.dialogValObj.startTime.getTime() : this.dialogValObj.startTime
      this.dialogValObj.endTime = typeof this.dialogValObj.endTime == 'object' ? this.dialogValObj.endTime.getTime() : this.dialogValObj.endTime
      if (!this.addFlag) return
      if (!this.dialogValObj.title.trim()) {
        this.$message.error(this.$t('lang.titleEmpty'))
        return
      }
      if (this.event.copyFlag) {
        this.dialogValObj.id = ''
      }
      this.addFlag = false
      if (this.event.editFlag && !this.event.copyFlag) {
        this.UpdateEventLogic(this.dialogValObj)
        return
      }
      // 转时间为当天的23：59：59
      this.dialogValObj.cycleEndTime = this.TimedayEnd(
        this.dialogValObj.cycleEndTime
      )
      this.axios({
        url: Outil.getUrl('/transcriberBooking/createEvent'),
        method: 'post',
        data: this.dialogValObj,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then((res) => {
        this.addFlag = true
        if (res.status == '200') {
          if (res.data.errorCode == '0x0') {
            Bus.$emit('queryBookingList')
            this.handleClose()
          } else {
            let errorInfo =
                this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
          return
        }
        Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
      }).catch(() => {
        this.addFlag = true
      })
    },
    // 更新事件逻辑
    UpdateEventLogic (param) {
      if (
        param.cycle != this.event.cycle ||
        param.cycleRule != this.event.cycleRule ||
        (param.cycleEndTime
          ? param.cycleEndTime != this.event.cycleEndTime
          : false) ||
        param.rpeerid != this.event.rpeerid
      ) {
        this.updateLoopEvent(2)
      } else if (
        param.startTime != this.event.startTime ||
        param.endTime != this.event.endTime ||
        param.title != this.event.title ||
        param.color != this.event.color
      ) {
        if (param.cycle) {
          this.TipType = {
            type: 'LoopEvent',
            Operation: 'update'
          }
          this.TipIsShow = true
          this.addFlag = true
        } else {
          this.updateLoopEvent(0)
        }
      } else {
        this.updateLoopEvent(0)
        this.addFlag = true
      }
    },
    // 更新事件
    updateLoopEvent (item) {
      let param = JSON.parse(JSON.stringify(this.dialogValObj))
      param.updateRule = item
      // 转时间为当天的23：59：59
      param.cycleEndTime = this.TimedayEnd(param.cycleEndTime)
      this.axios({
        method: 'post',
        url: Outil.getUrl('/transcriberBooking/updateEvent'),
        data: param,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then((res) => {
        this.addFlag = true
        if (res.status == '200') {
          if (res.data.errorCode == '0x0') {
            Bus.$emit('queryBookingList')
            this.handleClose()
          } else {
            let errorInfo =
                this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
          return
        }
        Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
      }).catch(() => {
        this.addFlag = true
      })
    },
    // 转时间到当天最后一刻
    TimedayEnd (time) {
      if (time != null && time != 0) {
        let hour = typeof time == 'object' ? time.getHours() : new Date(time).getHours()
        let millisecond = typeof time == 'object' ? time.getTime() : time
        return hour < 23 ? millisecond + (24 * 60 * 60 * 1000 - 1000) : millisecond
      } else {
        return null
      }
    },
    // 取消创建
    cancel (formName) {
      bookingAnalyze('bookingCreateEventclose', '/bk')
      this.handleClose()
    },
    // 计算live时间
    calcDuration (start, end) {
      if ((!start && start !== 0) || (!end && end !== 0)) {
        this.dialogValObj.duration = null
        return
      }
      if (typeof start == 'object') {
        start = start.getTime()
      }
      if (typeof end == 'object') {
        end = end.getTime()
      }
      let duration = (end - start) / 60000 > 0 ? `${Math.floor((end - start) / 60000)}min` : null
      this.$set(this.dialogValObj, 'duration', duration)
    },
    // 初始化搜索条件
    initSearchCondi () {
      this.sourceParam.condition = ''
      this.rParam.condition = ''
      this.sourceParam.pageNum = 1
      this.sourceParam.pageSize = 15
      this.rParam.pageNum = 1
    },
    // 搜索r列表
    searchR (value) {
      this.rParam.pageNum = 1
      this.rParam.condition = value
      this.getRList()
    },
    // 隐藏时间的下拉
    HideTimeSelete () {
      $('.pick_date_timebk').hide()
    },
    // 查询R列表 5/6将这里下拉接口改为pageSourceAvailableR,且在切换source时，R下拉框数据需要更新
    getRList () {
      $('.pick_date_timebk').hide()
      if (this.rTotalPag < this.rParam.pageNum) return
      this.axios({
        method: 'post',
        url: Outil.getUrl('/transcriberBooking/listSourceR'),
        data: this.rParam,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then((res) => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          let result = res.data.result
          this.rTotalPag = result.pages || 1
          let seachKey = this.rParam.condition.trim()
          if (result.total == 0 && seachKey == '') {
            this.RList = []
            this.$message.info(this.$t('lang.noPairDestiation'))
            return
          }
          this.rParam.condition = ''
          if (this.rParam.pageNum == 1) {
            this.RList = result.list
            // 这里需要判断，如果编辑数据本身是自动，后台会返回自动R信息，这时切换到手动时，不能将这个R添加到下拉框中
            const temp = filter(this.RList, { peerId: this.event.rpeerid })
            if (!temp.length && this.event.editFlag && result.list.length > 0) {
              this.RList.push({
                peerId: this.event.rpeerid,
                name: this.event.rname
              })
            }
          } else {
            if (this.event.editFlag) {
              const index = findIndex(result.list, {
                peerId: this.event.rpeerid
              })
              if (index >= 0) {
                result.list.splice(index, 1)
              }
            }
            this.RList = this.RList.concat(result.list)
          }
        } else {
          this.rParam.condition = ''
          Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
        }
      }).catch(() => {})
    },
    // change r
    changeR (val) {
      this.dialogValObj.rpeerid = val
    },
    // 更改创建开始时间
    changeStartTime (val) {
      // 如果开始时间小于当前时间
      if (val.getTime() <= new Date().getTime()) {
        this.dialogValObj.startTime = new Date(new Date().getTime() + 60000)
        // this.$message.error(this.$t('lang.startTimeLessThanCurTime'));
      }
      var endTime = 0
      if (this.dialogValObj.endTime && this.dialogValObj.endTime != '') {
        endTime = typeof this.dialogValObj.endTime == 'number' ? this.dialogValObj.endTime : this.dialogValObj.endTime.getTime()
        // 如果开始时间大于结束时间，改变结束时间
        if (this.dialogValObj.startTime.getTime() >= endTime) {
          this.dialogValObj.endTime =
            this.dialogValObj.startTime.getTime() + 60 * 60 * 1000
        }
      }
      this.calcDuration(this.dialogValObj.startTime, this.dialogValObj.endTime)
    },
    // 更改创建结束时间
    changeEndTime (val) {
      let timeStr = typeof val == 'object' ? val.getTime() : val
      // 如果结束时间前于当前时间
      if (timeStr <= new Date().getTime()) {
        this.dialogValObj.endTime = new Date().getTime() + 60 * 60 * 1000
        // this.$message.error(this.$t('lang.stopTimeLessThanCurTime'));
      }
      var startTime = 0
      if (this.dialogValObj.startTime && this.dialogValObj.startTime != '') {
        startTime = typeof this.dialogValObj.startTime == 'number' ? this.dialogValObj.startTime : this.dialogValObj.startTime.getTime()
      }
      if (this.dialogValObj.endTime <= startTime) {
        this.dialogValObj.endTime = startTime + 60 * 60 * 1000
      }
      this.calcDuration(this.dialogValObj.startTime, this.dialogValObj.endTime)
    }
  },
  filters: {
    // filter name
    filterName (name) {
      if (name && name.length > 50) {
        return `${name.slice(0, 50)}...`
      }
      return name
    }
  },
  beforeDestroy () {
    const nodeList = document.querySelectorAll('.create_box_time')
    nodeList.forEach((v) => {
      v.removeEventListener('focus', this.elClick)
    })
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
  .el-dialog__header {
    .el-dialog__headerbtn {
      font-size: 20px;
    }
  }
  .el-dialog__body {
    padding: 20px 0 0 !important;
    .create_booking_box {
      .form_content {
        max-height: 600px;
        overflow-y: auto;
        padding: 0 15px;
        .el-form-item {
          margin-bottom: 15px;
          &.is-required:not(.is-no-asterisk) {
            .el-form-item__label:before {
              color: #ff3300;
              margin-right: 0;
            }
          }
          .el-form-item__content {
            text-align: left;
            .el-input__suffix {
              display: none;
            }
            .el-input__inner,
            .el-textarea__inner,
            .el-input__inner:focus:focus {
              border: none !important;
              background: #444 !important;
            }
            .el-select {
              display: block;
              .el_tag.el-tag--info {
                color: #fff !important;
                background: #6b6d6b !important;
              }
              .el-select__input {
                color: #fff;
              }
              .el-input__suffix {
                display: block !important;
              }
              .el-select-dropdown {
                text-align: left;
                width: 487px;
              }
            }
            .el-input__icon.el-icon-time {
              display: none;
            }
            .el-date-editor.el-input {
              width: 150px;
              float: left;
            }
            .el-input--prefix .el-input__inner {
              padding-left: 5px;
              padding-right: 5px;
            }
            .el-radio-group {
              margin-top: 5px;
              .el-radio__input.is-disabled.is-checked .el-radio__inner {
                background-color: #33ab4f;
                background: #33ab4f;
                border-color: #33ab4f;
              }
              .el-radio.is-disabled.is-checked .el-radio__label {
                color: #33ab4f;
              }
            }
            .arrow-icon {
              position: absolute;
              bottom: 18px;
              right: 12px;
              z-index: 10;
            }
            .el-input__icon.el-input__validateIcon.el-icon-circle-check,
            .el-input__icon.el-input__validateIcon.el-icon-circle-close {
              display: none;
            }
          }
        }
        .SeleteEndTime {
          width: 100%;
          display: flex;
          p {
            width: 100px;
            margin: 0;
            span {
              color: #ff3300;
            }
          }
          .RepeatEndTime {
            width: 100% !important;
            margin-left: 10px;
            .el-input__prefix {
              width: 50px;
              position: absolute;
              left: 100%;
              transform: translateX(-50px);
              top: 0;
              font-size: 18px;
            }
            .el-input__suffix {
              display: none;
            }
          }
        }
        .deviceSource {
          width: 100%;
          height: 32px;
          background: #444;
          border-radius: 6px;
          color: #fff;
          padding-left: 15px;
          margin-top: 4px;
          line-height: 32px;
        }
        .TitleandColor {
          .el-input {
            width: 445px;
          }
          .color_box {
            height: 100%;
            padding-right: 22px;
            margin-top: 10px;
            .color {
              width: 20px;
              height: 20px;
              margin-left: 10px;
              box-sizing: content-box;
            }
            .el-icon-caret-bottom {
              font-size: 20px;
              position: absolute;
              top: 16%;
              right: 0px;
              transform: translateY(-50%);
              transform: rotate(0deg);
              transition: 0.3s;
            }
            .el-icon-caret-bottom.top {
              transform: rotate(-180deg);
            }
            .color_opt {
              width: 220px;
              height: 40px;
              background: #fff;
              color: #999;
              border-radius: 4px;
              position: absolute;
              right: -11px;
              top: 31px;
              padding: 10px 0;
              padding-left: 6px;
              font-size: 12px;
              line-height: 20px;
              z-index: 10000;
              li {
                width: 20px;
                height: 20px;
                cursor: pointer;
                margin-left: 10px;
              }
            }
            .color_opt.triangle::before {
              left: 191px;
            }
            .green {
              border: 1px solid #0a9a5a;
              background: #0fbe71;
            }
            .skyblue {
              background-color: #46c9e1;
              border: 1px solid #0ca595;
            }
            .yellow {
              background-color: rgb(255, 192, 0);
              border: 1px solid rgb(255, 192, 0);
            }
            .blue {
              background-color: #2f8df4;
              border: 1px solid #126ed3;
            }
            .orange {
              background-color: #ffc573;
              border: 1px solid #d8a966;
            }
            .pink {
              background-color: #ff6a92;
              border: 1px solid #ee3656;
            }
          }
        }
      }
      .btn_box {
        .el-form-item__content {
          text-align: center;
          margin-left: 0 !important;
          padding: 15px 0;
          .el-button {
            width: 120px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-picker-panel.el-date-picker.el-popper.pick_date_RepeatEnd {
  z-index: 20039 !important;
}
</style>
