import { render, staticRenderFns } from "./scrollBox.vue?vue&type=template&id=193df169&scoped=true"
import script from "./scrollBox.vue?vue&type=script&lang=js"
export * from "./scrollBox.vue?vue&type=script&lang=js"
import style0 from "./scrollBox.vue?vue&type=style&index=0&id=193df169&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "193df169",
  null
  
)

export default component.exports