<template>
  <div class="calendar pr">
    <div class="device_list">
      <div class="create_info">
        <div
          class="clearfix"
          v-if="creating">
          <div class="create_info_title f_left">
            <i class="iconfont">&#xe635;</i>
            <span class="title_text">{{ $t("lang.creating") }}:</span>
          </div>
          <div class="create_info_cont f_left">
            <span class="f_left ellipsis">{{ creatingObj.source }}</span>
            <span class="f_left ellipsis">{{ creatingObj.r }}</span>
            <span class="f_left ellipsis">{{ creatingObj.time }}</span>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div class="device_content">
        <!-- 设备R列表 -->
        <div class="r_list">
          <div class="device_title r_title">
            <span class="f_left">{{ $t("lang.destination") }}</span>
            <i
              class="iconfont icon-zoom f_right pointer"
              @click.stop="showEle('searchR')"></i>
          </div>
          <div
            class="search_ipt"
            v-show="calendar.searchR"
            @click="toggleR($event)">
            <input
              type="text"
              v-model="rPar.condition"
              :placeholder="$t('lang.searchTText')"
              class="device_condition"
              @keydown.enter="getRList('rList', 1)" />
            <i
              class="iconfont icon-zoom f_right pointer"
              @click="getRList('rList', 1)"></i>
            <i class="down_arrow"></i>
          </div>
          <div
            class="list_box"
            :style="{ height: rListBoxHeight }"
            v-show="isShow.rList">
            <scroll-box
              :deviceList="rList"
              :chooseIndex="chooseRIndex"
              @chooseDevice="chooseR"
              v-scroll="bindRScroll">
            </scroll-box>
          </div>
        </div>
      </div>
    </div>

    <div class="calendar_box">
      <!-- 时间选择 -->
      <div class="cal_date_opt f_left">
        <i
          class="iconfont pointer"
          @click="preDate">&#xe604;</i>
        <el-date-picker
          v-model="dateTitle"
          type="date"
          popper-class="pick_date_time"
          @change="changeDateView"></el-date-picker>
        <i
          class="iconfont pointer"
          @click="nextDate">&#xe61b;</i>
      </div>
      <!-- 日历 -->
      <full-calendar
        ref="calendar"
        v-if="calendarFlag"
        v-loading="loadingCalendar"
        element-loading-background="rgba(0, 0, 0, .6)"
        :event-sources="eventSources"
        @event-selected="eventSelected"
        @event-created="eventCreated"
        @day-click="dayClick"
        :config="calendarConfig"></full-calendar>
      <!-- 创建框 -->
      <div
        class="create_booking triangle"
        v-if="isShow.createBox"
        :style="{
          left: createBox.left,
          top: createBox.top,
          height: createBox.height,
        }"
        :class="{
          right: createBox.triangle,
          bottom: createBox.bottom,
          device: !isShow.Transcriber,
        }"
        v-clickoutside="closeCreateBox"
        @click="closeDownList">
        <!-- 创建头部 -->
        <div class="create_title clearfix">
          <ul
            class="f_left"
            v-if="!isShow.edit">
            <li
              class="f_left"
              @click="showCreateBoxCon('Transcriber')"
              :class="{ active: isShow.Transcriber }">
              {{ $t("lang.Transcriber") }}
            </li>
            <li class="f_left separator">|</li>
            <li
              class="f_left"
              @click="showCreateBoxCon('device')"
              :class="{ active: !isShow.Transcriber }">
              {{ $t("lang.device") }}
            </li>
          </ul>
          <div v-if="isShow.edit">
            <p
              class="event_color f_left"
              :class="selColor"></p>
            <p class="event_title f_left ellipsis">{{ eventDetail.title }}</p>
          </div>
          <div
            class="iconfont f_right pointer"
            @click.stop="closeCreateBox">
            &#xe603;
          </div>
        </div>
        <!-- 编辑的Transcriber -->
        <div
          class="Transcriber"
          v-if="!isShow.edit && isShow.Transcriber">
          <div class="c_item booking_title">
            <input
              type="text"
              class="f_left"
              maxlength="32"
              v-model="event.title"
              :placeholder="$t('lang.createTitle')"
              :disabled="disable.input"
              :class="{ gray: disable.input }" />
            <!-- 颜色选择 -->
            <div
              class="color_box f_right pr pointer"
              @click.stop="showColorOpt">
              <div
                class="color f-left"
                :class="selColor"></div>
              <i
                class="el-icon-caret-bottom"
                :class="{ top: isShow.showColorOpt }"></i>
              <div
                class="color_opt triangle"
                v-if="isShow.showColorOpt">
                <span class="f_left">{{ $t("lang.color") }}</span>
                <ul class="f_left">
                  <li
                    class="f_left green"
                    @click="chooseColor('green', 'rgb(15, 190, 113)')"></li>
                  <li
                    class="f_left skyblue"
                    @click="chooseColor('skyblue', 'rgb(70, 201, 225)')"></li>
                  <li
                    class="f_left blue"
                    @click="chooseColor('blue', 'rgb(47, 141, 244)')"></li>
                  <li
                    class="f_left orange"
                    @click="chooseColor('orange', 'rgb(255, 197, 115)')"></li>
                  <li
                    class="f_left pink"
                    @click="chooseColor('pink', 'rgb(255, 106, 146)')"></li>
                  <li
                    class="f_left yellow"
                    @click="chooseColor('yellow', 'rgb(255, 192, 0)')"></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="c_item">
            <div class="time_opt start_time f_left">
              <el-date-picker
                v-model="createStartTime"
                type="datetime"
                popper-class="pick_date_time"
                @change="changeStartTime"
                :placeholder="$t('lang.selectData')"
                :picker-options="pickerDateBeg"
                :disabled="disable.input"
                :class="{ gray: disable.input }"
                format="MM-dd-yyyy hh:mm:ss"
                class="create_box_time">
              </el-date-picker>
            </div>
            <div class="f_left to">to</div>
            <div class="time_opt end_time f_right">
              <el-date-picker
                v-model="createStopTime"
                popper-class="pick_date_time"
                :picker-options="pickerDateEnd"
                @change="changeEndTime"
                type="datetime"
                :placeholder="$t('lang.selectData')"
                format="MM-dd-yyyy hh:mm:ss"
                class="create_box_time">
              </el-date-picker>
            </div>
          </div>
          <div class="c_item">
            <div class="LoopWrap">
              <el-select
                class="LoopSelect"
                v-model="LoopSelete"
                @change="SeleteLoopChang"
                :popper-append-to-body="false"
                @focus="HideTimeSelete()">
                <el-option
                  v-for="item in LoopOptions"
                  :key="item.type"
                  :label="item.label"
                  :value="item.type">
                </el-option>
              </el-select>
              <el-date-picker
                v-if="LoopSelete != 'noloop'"
                :placeholder="$t('lang.endRepeat')"
                format="MM-dd-yyyy"
                value-format="timestamp"
                class="EndTime create_box_time"
                v-model="event.cycleEndTime"
                type="date"
                :picker-options="pickerRepeatEndTime"
                popper-class="pick_date_time">
              </el-date-picker>
            </div>
          </div>
        </div>
        <!-- 编辑的device -->
        <div
          class="device"
          v-if="!isShow.edit && !isShow.Transcriber">
          <div class="d_item source clearfix">
            <span class="f_left">{{ $t("lang.source") }}</span>
            <span class="f_right val ellipsis">{{ event.tname }}</span>
          </div>
          <div class="d_item clearfix pr">
            <span class="f_left">{{ $t("lang.destination") }}</span>
            <span
              class="f_right val pointer ellipsis"
              @click.stop="showCreBoxRList">{{ event.rname }}
              <!-- :class="{
                gray: disable.input,
                triangle: !disable.input,
                up: isShow.createBoxRList,
              }" -->
              <!-- <div
                class="createbox_r_list"
                :class="{ show_height: isShow.showHeight }"
                v-if="isShow.createBoxRList"
              >
                <scroll-box
                  :deviceList="createRList"
                  @chooseDevice="selR"
                  v-scroll="bindCreBoxRScroll"
                ></scroll-box>
              </div> -->
            </span>
          </div>
        </div>
        <!-- 展示信息 -->
        <div
          class="event_content_detail"
          v-if="isShow.edit">
          <p class="detail_item clearfix">
            <span class="f_left">{{ $t("lang.time") }}</span>
            <span class="f_right ellipsis">{{ eventDetail.time }}</span>
          </p>
          <p class="detail_item clearfix">
            <span class="f_left">{{ $t("lang.source") }}</span>
            <span class="f_right ellipsis">{{ eventDetail.tname }}</span>
          </p>
          <p class="detail_item clearfix">
            <span class="f_left">{{ $t("lang.destination") }}</span>
            <span class="f_right ellipsis">{{ eventDetail.rname }}</span>
          </p>
        </div>
        <div class="create_footer clearfix">
          <button
            class="save f_right"
            v-show="!isShow.edit"
            v-repeat-click="{ event: saveAddBooking }">
            {{ $t("lang.save") }}
          </button>
          <button
            class="cancel f_right"
            v-show="!isShow.edit"
            @click="closeCreateBox">
            {{ $t("lang.cancel") }}
          </button>
          <button
            class="delete f_left"
            v-show="isShow.edit && isShow.deleteBtn && curLiveStatus != 5"
            @click="deleteEvent">
            {{ $t("lang.delete") }}
          </button>
          <button
            class="copy f_left"
            v-show="isShow.edit && isShow.deleteBtn && curLiveStatus != 5"
            @click="copyEvent">
            {{ $t("lang.copy") }}
          </button>
          <button
            class="end_live f_left"
            v-show="isShow.edit && !isShow.deleteBtn"
            @click="endLivingEvent">
            {{ $t("lang.goodNight") }}
          </button>
          <button
            class="edit f_right"
            v-show="isShow.edit"
            @click="editEvent">
            {{ $t("lang.edit") }}
          </button>
        </div>
      </div>
    </div>

    <!-- 各种操作的提示框 -->
    <div class="TipFrame">
      <action-tip
        v-if="TipIsShow"
        :TipType="TipType"
        @CloseDialog="TipIsShow = false"
        @deleteLoopEvent="deleteLoopEvent"
        @updateLoopEvent="updateLoopEvent"></action-tip>
    </div>
  </div>
</template>
<script>
// import moment from 'moment'
import Outil from '@/assets/js/utils.js'
import CreatingInfo from '@/assets/js/bokigCommon.js'
// import Cascader from '@/components/Cascader'
import scrollBox from './scrollBox'
import { bookingAnalyze } from '@/assets/js/googleAnalyze/booking.js'
import toTips from '@/assets/js/toTipsObj'
import ActionTip from './ActionTip.vue'
// import { color } from 'highcharts'
import Bus from '@/assets/js/vueBus.js'
import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/fullcalendar.css'

export default {
  components: {
    // Cascader,
    scrollBox,
    FullCalendar,
    ActionTip
    // magnify
  },
  props: ['bookmark'],
  data () {
    return {
      // 弹出框是否显示
      TipIsShow: false,
      // 提示类型
      TipType: {
        type: 'noLoopEvent', // LoopEvent 循环事件  noLoopEvent 普通事件
        Operation: 'delete' // delete 删除事件  update 更新事件
      },
      bindRScroll: {
        // r绑定滚动事件
        onScroll: this.handleRScroll,
        distance: 10,
        directive: '.scroll_box ul'
      },
      bindCreBoxRScroll: {
        // 创建框中的r绑定滚动事件
        onScroll: this.handleCreBoxRScroll,
        distance: 10,
        directive: '.scroll_box ul'
      },
      dateInfo: {
        show: false,
        date: {
          start: '',
          end: '',
          date: [],
          weekTitle: []
        }
      },
      // 日历加载
      loadingCalendar: false,
      calendarFlag: true,
      calendarHeight: '',
      curLiveStatus: null,
      // 编辑及创建的事件信息
      event: {
        title: '',
        rname: '', // r的名字
        tname: 'SDI', // SDI  source
        startTime: '', // 开始时间
        endTime: '', // 结束时间
        color: 'rgb(15, 190, 113)', // 颜色
        rpeerid: '', // rid
        tpeerid: '0000000000000001', // tid source
        ttype: '',
        turl: '',
        id: '', // 事件id
        duration: '', // 事件时长
        cycle: false, // 是否循环
        cycleRule: 1, // 循环规则 daily：按日循环，weekly:按每周几循环，everyWeekday：每周工作日循环，everyWeekend:每周周末循环
        dayOfWeek: 1, // 当cycleRule为weekly时，需要传入是周几。1-7分别代表周一到周日
        cycleEndTime: null // 循环结束时间
      },
      // 对于循环事件的选择
      LoopSelete: 'noloop',
      // 循环事件的选项
      LoopOptions: [
        {
          type: 'noloop',
          label: 'Does not repeat'
        },
        {
          type: 1,
          label: 'Daily'
        },
        {
          type: 2,
          label: 'Weekly on Tuesday'
        },
        {
          type: 3,
          label: 'Every weekday (Monday to Friday)'
        },
        {
          type: 4,
          label: 'Every weekend (Saturday and Sunday)'
        }
      ],
      // 周几
      weekTitleList: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ],
      deleteId: '',
      loading: {
        source: false
      },
      calendar: {
        searchS: false,
        searchR: false,
        filterBox: false
      },
      pickerDateBeg: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return (
            time.getTime() <
            new Date(
              date.year + '-' + date.month + '-' + date.day + ' 00:00:00'
            ).getTime()
          )
        }
      },
      pickerDateEnd: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return (
            time.getTime() <
            new Date(
              date.year + '-' + date.month + '-' + date.day + ' 00:00:00'
            ).getTime()
          )
        }
      },
      createBox: {
        // 创建框的位置
        left: 0,
        top: 0,
        triangle: false,
        bottom: false,
        height: '3rem'
      },
      createStartTime: '',
      createStopTime: '',
      startDate: '',
      dateTitle: '', // 日历头部时间title
      events: [],
      eventFrom: '',
      calendarConfig: {
        // full-calendar的配置参数
        buttonText: {
          today: this.$t('lang.todayKey'),
          month: this.$t('lang.monthKey'),
          week: this.$t('lang.weekKey'),
          day: this.$t('lang.dayKey')
        },
        header: {
          left: '',
          center: 'prev,title,next'
        },
        navLinks: true,
        selectable: true,
        selectHelper: true,
        dayRender: (date, cell) => { },
        firstDay: new Date().getDay(), // 日历从哪一天开始
        columnFormat: 'ddd M-D',
        // slotDuration: '00:00:10',
        defaultView: 'agendaWeek',
        allDaySlot: false,
        firstHour: 1,
        slotEventOverlap: false, // 事件不可重叠
        editable: false, // 事件不可拖动缩放
        eventLimit: true, // allow "more" link when too many events
        borderColor: '#333',
        // height: 902,
        contentHeight: 800,
        // selectHelper: true,
        unselectAuto: false, // 设置当点击页面其他地方的时候，是否清除已选择的区域，值为布尔类型
        loading: (isLoading, view) => {
          if (
            $('.calendar_box .el-date-editor.el-date-editor--date').find(
              '.replace_title'
            ).length == 0
          ) {
            $('.calendar_box .el-input__inner').after(
              '<p class="replace_title"></p>'
            )
          }
          $('.el-input__inner').css({
            border: 'none',
            backgroundColor: 'transparent'
          })
          // 阻止冒泡
          $('.fc-body').on('click', (e) => {
            e.stopPropagation()
          })
          // 显示时间标题
          this.changeCalTitle()
          if (!isLoading) {
            var time = view.calendar.currentDate._d
            this.dateTitle =
              time.getFullYear() +
              '-' +
              Outil.timeFormat(time.getMonth() + 1) +
              '-' +
              Outil.timeFormat(time.getDate())
            // 切换日历视图
            $('.fc-button-group button').on('click', () => {
              this.changeCalTitle()
              // 阻止冒泡
              $('.fc-body').on('click', (e) => {
                e.stopPropagation()
              })
            })
          }
          // 添加下划线
          $('.cal_date_opt .el-input__inner').on('mouseenter', () => {
            $('.replace_title').css('text-decoration', 'underline')
          })
          $('.cal_date_opt .el-input__inner').on('mouseleave', () => {
            $('.replace_title').css('text-decoration', 'none')
          })
        },
        viewRender: (view, element) => {
          /*
                     * 渲染视图后
                     * 美化滚动条
                     * if (view.type !== "month") $(".fc-time-grid-container").fnNiceScroll();
                     * 更新表格标题
                     */
          var title = $('.fc-toolbar .fc-center h2').html()
          $('.cal-header .cal-date').html(title)
          // 更新模式选择
          var lis = $('.cal-type li')
          lis.map((item, idx) => {
            if ($(item).attr('data-type') === view.type) {
              $(item).addClass('active').siblings('li').removeClass('active')
            }
          })
          setTimeout(() => {
            this.addCopyIcon()
            $('.fc-more').click(() => {
              this.addCopyIcon('.fc-popover.fc-more-popover')
            })
          })
        },
        // 点击被创建的事件
        eventClick: (event, jsEvent, view) => {
          let start = Outil.getDateTime(event.start._d)
          let stop = Outil.getDateTime(event.end._d)
          this.isShow.edit = true
          this.jsEventObj = jsEvent.currentTarget
          let calendarScrollTop =
            $('.fc-scroller.fc-time-grid-container').scrollTop() || 0
          let mainScrollTop = $('.el-main').scrollTop() || 0
          if (this.eventFrom == 'copyIcon') {
            this.isShow.edit = false
          } else {
            this.isShow.create = false
          }
          this.eventFrom = ''
          let position
          if (
            start.year == stop.year &&
            start.month == stop.month &&
            start.day == stop.day
          ) {
          } else {
            position = {
              left: jsEvent.clientX - jsEvent.offsetX,
              // top: jsEvent.clientY - 210 + calendarScrollTop + (jsEvent.clientY * 2 > $('.fc-view-container').height() + mainScrollTop ? 0 : mainScrollTop),    // 220为所有头部的高度和+三角的高度
              top:
                jsEvent.clientY -
                210 +
                calendarScrollTop +
                (jsEvent.clientY * 2 + calendarScrollTop + mainScrollTop >
                  $('.fc-view-container').height()
                  ? mainScrollTop
                  : 0), // 210为所有头部的高度和+三角的高度; +-20为三角高度
              bottom:
                jsEvent.clientY * 2 + calendarScrollTop + mainScrollTop >
                $('.fc-view-container').height()
            }
          }
          this.fillCreateBox(event) // 编辑框内的数据
          this.setCreateBox(event.start._d, event.end._d, position)
          // bookingAnalyze(`bookingCalendarEventDetailBoxOpen`, '/bk');
        },
        select: (start, end, event, view, resource) => {
          if (this.isShow.createBox) {
            // 点击日历，隐藏创建框
            $('.create_booking').click()
          }
          if (this.chooseRIndex != null) {
            // 是否有选中的R和源
            var id = new Date().getTime()
            var UTCStartTime = Outil.getUTCTime(start._d)
            var UTCEndTime = Outil.getUTCTime(end._d)
            /*
                         * var startDate = new Date(UTCStartTime)
                         * var endDate = new Date(UTCEndTime)
                         */
            this.clearDataAndUpdate(
              new Date(UTCStartTime),
              new Date(UTCEndTime),
              id
            )
            $('a.fc-time-grid-event.fc-event.fc-helper').addClass('antline')
            return
          }
          $('#calendar').fullCalendar('unselect')
        },
        selectAllow: (selectInfo) => {
          // 自定义可选取范围
          var date = selectInfo.start._d
          // var UTCStartTime = date.getTime() + date.getTimezoneOffset() * 60000;
          var UTCStartTime = Outil.getUTCTime(date)
          if (UTCStartTime < new Date()) {
            if (this.isShow.createBox) {
              // 不取消上次选中时间
              $('#calendar').fullCalendar(
                'select',
                this.startDate,
                this.endDate
              )
            }
            return false
          } else {
            return true
          }
        },
        dayClick: (event, jsEvent, view) => {
          jsEvent.stopPropagation()
          this.selected = event
        }
        // eventSources: () => {

        // }
      },
      selected: {},
      totalPage: {
        // 设备的总页数
        source: 1,
        r: 1
      },
      lastCondition: {
        // 最后一次搜索的条件
        r: ''
      },
      rPar: {
        // 获取R列表的参数
        condition: '',
        /*
                 * bookmardIds: "all",
                 * unClassified: true,
                 */
        pageNum: 1,
        pageSize: 35,
        booking: true
      },
      lastLeftRPageNum: 1,
      lastrightRPageNum: 1,
      rList: [],
      chooseRIndex: null, // 选中的R下标
      // 选中r的信息
      RPitchMessage: {},
      creating: false, // 正在创建的左头部Booking信息
      creatingInfo: new CreatingInfo([]),
      creatingObj: {
        // 创建中的booking信息
        source: '',
        r: '',
        time: ''
      },
      isShow: {
        showColorOpt: false,
        createBox: false,
        Transcriber: true,
        showHeight: false,
        createBoxRList: false,
        bookmark: true,
        edit: true,
        deleteBtn: true,
        rList: true
      },
      isCopy: false,
      disable: {
        input: false
      },
      selColor: 'green',
      addFlag: true,
      createRList: [], // 创建框中的R列表
      jsEventObj: '',
      eventDetail: {
        // 点击booking后的展示框内数据
        title: '',
        time: '',
        tname: '',
        r: '',
        cycle: false
      },
      pickerRepeatEndTime: {
        disabledDate (time) {
          var date = Outil.getDateTime()
          return (
            time.getTime() <
            new Date(
              date.year + '-' + date.month + '-' + date.day + ' 00:00:00'
            ).getTime()
          )
        }
      }
    }
  },
  created () {
    this.createStartTime = new Date()
    this.createStopTime = new Date()
    window.addEventListener('resize', this.handleResize)
    // 获取r
    this.getRList('rList', 1)

    Bus.$on('langSeltSwitch', (val) => {
      this.closeCreateBox()
      if (!this.$i18n) {
        return
      }
      this.calendarConfig.buttonText = {
        today: this.$t('lang.todayKey'),
        month: this.$t('lang.monthKey'),
        week: this.$t('lang.weekKey'),
        day: this.$t('lang.dayKey')
      }
      this.calendarFlag = false
      this.handleResize()
      this.$nextTick(() => {
        this.calendarFlag = true
        this.getBookingEventAccodT()
      })
    })
  },
  mounted () { },
  watch: {
    createStartTime (val) {
      if (val) this.event.startTime = val.getTime()
      this.pickerRepeatEndTime.disabledDate = this.RepeatDisabledDate
    },
    createStopTime (val) {
      if (val) this.event.endTime = val.getTime()
    },
    chooseRIndex () {
      this.RPitchMessage = this.rList[this.chooseRIndex]
      // 监听r变化
      this.getBookingEventAccodT()
    }
    /*
     * 监听弹出框变化
     * "isShow.createBox"(value) {
     *   if (value) {
     *     this.$nextTick(() => {
     *       // 为了解决点击时间选择后，不关闭时间框就去点击source下拉出现的问题
     *       const nodeList = document.querySelectorAll(".create_box_time");
     *          console.log(nodeList)
     *       nodeList.forEach((v) => {
     *         v.addEventListener("focus", this.elClick, true);
     *       });
     *     });
     *   }
     * },
     */
  },
  methods: {
    // 隐藏时间的下拉
    HideTimeSelete () {
      // $(".pick_date_time").hide();
    },
    /*
         * elClick() {
         *   $(".calendar_box .create_booking").click();
         * },
         * 时间禁止选择
         */
    RepeatDisabledDate (time) {
      return time.getTime() < this.createStartTime.getTime()
    },
    // 选择循环变化时
    SeleteLoopChang (item) {
      if (item == 'noloop') {
        this.event.cycle = false
      } else {
        this.event.cycle = true
        this.event.cycleRule = item
      }
    },
    // 获取事件在周几
    getEventWeekday (startTime) {
      let weekIndex =
        typeof startTime == 'object'
          ? startTime.getDay()
          : new Date(startTime).getDay()
      if (weekIndex == 0) {
        weekIndex = 7
      }
      //  设置选项
      this.$set(this.LoopOptions, 2, {
        type: 2,
        label: `Weekly on ${this.weekTitleList[weekIndex - 1]}`
      })
      this.event.dayOfWeek = weekIndex
      if (this.event.cycle) {
        if (this.event.cycleRule) {
          this.LoopSelete = this.event.cycleRule
        }
      } else {
        this.LoopSelete = 'noloop'
      }
    },
    // 清空数据和更新信息
    clearDataAndUpdate (start, end, id, position) {
      // 设置创建框d
      this.isShow.edit = false
      this.jsEventObj = ''
      this.event = {
        title: '',
        rname: this.RPitchMessage.name, // r的名字
        tname: 'SDI', // SDI  source
        startTime: '', // 开始时间
        endTime: '', // 结束时间
        color: 'rgb(15, 190, 113)', // 颜色
        rpeerid: this.RPitchMessage.peerId, // rid
        tpeerid: '0000000000000001', // tid source
        ttype: '',
        turl: '',
        id: '', // 事件id
        duration: '', // 事件时长
        cycle: false, // 是否循环
        cycleRule: 1, // 循环规则 daily：按日循环，weekly:按每周几循环，everyWeekday：每周工作日循环，everyWeekend:每周周末循环
        dayOfWeek: 1, // 当cycleRule为weekly时，需要传入是周几。1-7分别代表周一到周日
        cycleEndTime: null // 循环结束时间
      }
      this.addFlag = true
      this.upeHeaderCreateBookTime(start, end, id) // 更新页面头部左侧创建事件的信息
      this.setCreateBox(start, end, position)
      // 获取初始时间在周几
      this.getEventWeekday(start)
      this.isShow.createBox = true
      if (position && position.important) {
        // zoom窗口中的打开创建框不需发送谷歌分析
        return
      }
      bookingAnalyze('bookingCalendarCreateBoxOpen', '/bk')
    },
    // 窗口大小调整
    handleResize () {
      // this.calendarFlag = false;
      this.isShow.createBox = false
      if (document.body.clientWidth > 1024) {
        this.isShow.rList = true
        this.calendar.searchS = false
        this.calendar.searchR = false
      } else {
        this.isShow.rList = false
        this.calendar.searchS = true
        this.calendar.searchR = true
      }
      this.creating = false
    },
    handleRScroll () {
      // r滚动到底部处理事件
      this.rPar.pageNum = this.lastLeftRPageNum
      if (this.totalPage.r < this.rPar.pageNum) {
        return
      }
      this.rPar.pageNum++
      this.lastLeftRPageNum = this.rPar.pageNum
      this.getRList('rList')
    },
    handleCreBoxRScroll () {
      // 创建框中滚动到底部处理事件
      this.rPar.pageNum = this.lastrightRPageNum
      if (this.totalPage.r < this.rPar.pageNum) {
        return
      }
      this.rPar.pageNum++
      this.lastrightRPageNum = this.rPar.pageNum
      this.getRList('createRList')
    },
    // 显示编辑框
    editEvent () {
      bookingAnalyze('bookingCalendarEventEditOpen', '/bk')
      this.isShow.edit = false
    },
    copyEvent () {
      this.editEvent()
      // 循环改非循环
      this.$set(this.event, 'cycle', false)
      this.LoopSelete = 'noloop'
      this.isCopy = true
      this.isShow.create = true
    },
    // 编辑booking事件
    fillCreateBox (event) {
      this.isShow.deleteBtn = true
      this.curLiveStatus = null
      let titleArr = event.title.split(' ') // 更改编辑框内的title
      titleArr.splice(0, 1)
      // 获取事件的主要信息
      var otherInfo = event.id.split('&')

      let infoObj = {}
      for (var i = 0; i < otherInfo.length; i++) {
        let arr = otherInfo[i].split('=')
        if (arr[0] == 'cycleEndTime' || arr[0] == 'cycleRule') {
          arr[1] = Number(arr[1])
        } else if (arr[0] == 'cycle') {
          if (arr[1] == 'false') {
            arr[1] = false
          } else {
            arr[1] = true
          }
        }
        infoObj[arr[0]] = arr[1]
      }
      infoObj.tname = infoObj.tname != 'undefined' ? infoObj.tname : 'SDI'
      this.event = JSON.parse(JSON.stringify(infoObj))
      this.event.title = titleArr.join('')
      this.event.cycleEndTime = this.event.cycleEndTime ? this.event.cycleEndTime : null
      // 获取颜色
      this.chooseColor(event.colorName, event.backgroundColor, false)
      this.createStartTime = event.start._d // 开始时间
      this.createStopTime = event.end._d // 结束时间
      // 获取初始时间在周几
      this.getEventWeekday(this.createStartTime)

      // 更改展示框内的内容
      this.eventDetail = JSON.parse(JSON.stringify(this.event))
      this.eventDetail.startTime = this.createStartTime.getTime()
      this.eventDetail.endTime = this.createStopTime.getTime()
      var startDate = Outil.getDateTime(event.start._d)
      var endDate = Outil.getDateTime(event.end._d)
      var startDay =
        startDate.day + '-' + startDate.month + '-' + startDate.year
      var endDay = endDate.day + '-' + endDate.month + '-' + endDate.year
      if (startDay == endDay) {
        this.eventDetail.time = `${startDay} ${startDate.hour}:${startDate.min}:${startDate.s} - ${endDate.hour}:${endDate.min}:${endDate.s}`
      } else {
        this.eventDetail.time = `${startDay} ${startDate.hour}:${startDate.min} - ${endDay} ${endDate.hour}:${endDate.min}`
      }

      this.deleteId = infoObj.id // 保存点击的事件id
      // 如果已经开始live了
      if (event.start._d.getTime() <= new Date().getTime()) {
        this.eventStatus(infoObj.id)
      } else {
        this.isShow.createBox = true
      }
    },
    // 判断事件状态
    eventStatus (eventId) {
      this.axios({
        method: 'post',
        url: Outil.getUrl('/event/getEventById'),
        data: { eventId: eventId, booking: true },
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then((res) => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          let result = res.data.result
          if (result.liveStatus == 1 || result.liveStatus == 5) {
            // running
            if (result.liveStatus == 1) {
              this.isShow.deleteBtn = false
            }
            this.curLiveStatus = result.liveStatus
            this.changeInputStatus(true)
            this.isShow.createBox = true
          } else if (result.liveStatus === 2 || result.liveStatus === 4) {
            this.removeCalendarEvent(eventId)
          }
        }
      }).catch(() => {})
    },
    // 移除已经结束的事件
    removeCalendarEvent (eventId) {
      $('#calendar').fullCalendar('removeEvents', eventId)
      this.$message.error(this.$t('lang.invalidEvent'))
    },
    // 更改输入状态
    changeInputStatus (status) {
      this.disable.input = status
    },
    // 结束live
    endLivingEvent () {
      bookingAnalyze('bookingCalendarEventLiveEnd', '/bk')
      this.$confirm(this.$t('lang.sureDeleteEvent'), '', {
        confirmButtonText: this.$t('lang.Ok'),
        cancelButtonText: this.$t('lang.cancel'),
        type: ''
      }).then(() => {
        this.axios({
          method: 'post',
          url: Outil.getUrl('/event/endEventById'),
          data: { eventId: this.deleteId, booking: true },
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        }).then((res) => {
          if (res.status == 200) {
            if (res.data.errorCode == '0x0') {
              $('#calendar').fullCalendar('removeEvents', this.deleteId)
              this.isShow.createBox = false
            } else {
              let errorInfo =
                this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
              this.$message.error(errorInfo)
            }
          }
        }).catch(() => {})
      }).catch(() => {})
    },
    // 设置创建框
    setCreateBox (startDate, endDate, position) {
      this.startDate = startDate
      this.createStartTime = startDate
      this.createStopTime = endDate
      this.endDate = endDate
      let view = $('#calendar').fullCalendar('getView')
      let fifthDay = $('.fc-row.fc-widget-header thead tr>th').eq(5).attr('data-date')
      let createEventPos = position || this.getEventPro() // 获取创建事件的位置
      this.initCreateBox() // 初始化创建框
      this.getCreateBoxTop(position) // 设置创建框的位置
      this.changeInputStatus(false) // 可以输入title，时间等信息
      let deviceLeftWidth =
        document.body.clientWidth > 1024 ? $('.device_list').width() : 0
      if (view.name == 'month') {
        if (startDate.getDay() > 0 && startDate.getDay() < 5) {
          this.createBox.left =
            (createEventPos.left - 320 - deviceLeftWidth) / 100 + 'rem'
          this.createBox.triangle = true
        } else {
          this.createBox.left =
            (createEventPos.left - 40 - deviceLeftWidth) / 100 + 'rem'
          this.createBox.triangle = false
        }
      } else {
        if (
          startDate.getTime() + 8 * 60 * 60 * 1000 >=
          new Date(fifthDay).getTime()
        ) {
          this.createBox.left =
            (createEventPos.left - 320 - deviceLeftWidth) / 100 + 'rem'
          this.createBox.triangle = true
        } else {
          this.createBox.left =
            (createEventPos.left - 40 - deviceLeftWidth) / 100 + 'rem'
          this.createBox.triangle = false
        }
      }
      // 获取创建框内的R列表
      this.rPar.pageNum = 1
      this.lastrightRPageNum = 1
      this.getRList('createRList')
      // 禁止里层滚动条滚动
      $('.fc-scroller.fc-time-grid-container').css('overflow-y', 'hidden')
    },

    // 更改创建开始时间
    changeStartTime (val) {
      // 如果开始时间前于当前时间
      if (val.getTime() <= new Date().getTime()) {
        this.createStartTime = new Date(new Date().getTime() + 60000)
        this.$message.error(this.$t('lang.startTimeMoreCurTime'))
      }
      // 如果开始时间大于结束时间，改变结束时间
      var duration = ''
      if (this.createStopTime) {
        duration = (this.createStopTime.getTime() - val.getTime()) / 60000
      }
      if (duration < 0) {
        this.createStopTime = new Date(val.getTime() + 3600000)
      }
      // 持续时间
      if (this.createStopTime) {
        this.duration =
          (this.createStopTime.getTime() - val.getTime()) / 60000 + 'min'
      }
      // 如果是更新事件不显示创建头
      if (this.event.id) return
      // 更新头部的创建时间
      this.upeHeaderCreateBookTime(
        $.fullCalendar.moment(val),
        $.fullCalendar.moment(this.createStopTime),
        this.creatingInfo.temId
      )
    },
    // 更改创建结束时间
    changeEndTime (val) {
      // 如果结束时间前于当前时间
      let errorMsg = ''
      if (val.getTime() < new Date().getTime()) {
        this.createStopTime = new Date(
          typeof this.createStartTime == 'object' ? this.createStartTime.getTime() : this.createStartTime + 60000
        )
        errorMsg = this.$t('lang.endTimeMoreCurTime')
      }
      var duration = ''
      if (this.createStartTime) {
        duration = (val.getTime() - this.createStartTime.getTime()) / 60000
      }
      if (duration < 0) {
        this.createStopTime = new Date(
          this.createStartTime.getTime() + 3600000
        )
        errorMsg = this.$t('lang.endTimeMoreStartTime')
      }
      if (errorMsg) {
        this.$message.error(errorMsg)
      }
      // 持续时间
      if (this.createStartTime) {
        this.duration = (this.createStopTime.getTime() - this.createStartTime.getTime()) / 60000 + 'min'
      }
      // 如果是更新事件不显示创建头
      if (this.event.id) return
      // 更新头部的创建时间
      this.upeHeaderCreateBookTime(
        $.fullCalendar.moment(this.createStartTime),
        $.fullCalendar.moment(val),
        this.creatingInfo.temId
      )
    },
    // 关闭创建box
    closeCreateBox (isClick) {
      if (isClick) {
        let googleAnalyzeEvent = this.dateInfo.show
          ? 'bookingZoomCreatedBoxClose'
          : 'bookingCalendarEventEditClose'
        bookingAnalyze(googleAnalyzeEvent, '/bk')
      }
      this.creating = false
      $('#calendar').fullCalendar('unselect') // 清空选中区域
      this.isShow.createBox = false
      this.editStatus = {}
      $('.fc-scroller.fc-time-grid-container').css('overflow-y', 'scroll') // 开启里层滚动条滚动
      // 初始化颜色选择框
      this.selColor = 'green'
      this.event.color = 'rgb(15, 190, 113)'
      this.isCopy = false
    },
    changeCalTitle () {
      // 更改日历title
      var title = $('.fc-center h2').html()
      $('.replace_title').html(title)
    },
    preDate () {
      // 上一视图日历
      bookingAnalyze('bookingDatePreClick', '/bk')
      $('#calendar').fullCalendar('prev')
      this.changeCalTitle()
    },
    nextDate () {
      // 下一视图日历
      bookingAnalyze('bookingDateNextClick', '/bk')
      $('#calendar').fullCalendar('next')
      this.changeCalTitle()
    },
    refreshEvents () {
      this.$refs.calendar.$emit('refetch-events')
    },
    removeEvent () {
      this.$refs.calendar.$emit('remove-event', this.selected)
      this.selected = {}
    },
    eventSelected (event) {
      this.selected = event
    },
    eventCreated () {
      this.config.selectable = false
    },
    dayClick (event, jsEvent, view) {
      jsEvent.stopPropagation()
    },
    // 显示对应的元素
    showEle (keyVal) {
      if (keyVal == 'searchR') {
        bookingAnalyze('bookingRConditionClick', '/bk')
      }
      for (var key in this.calendar) {
        if (keyVal == key) {
          this.calendar[key] = !this.calendar[key]
        } else {
          this.calendar[key] = false
        }
      }
      if (document.body.clientWidth <= 1024) {
        this.calendar.searchS = true
        this.calendar.searchR = true
      }
    },
    // 获取R列表
    getRList (rList, first) {
      if (this.lastCondition.r != this.rPar.condition) {
        // 搜索条件更改时
        this.lastCondition.r = this.rPar.condition
        this.rPar.pageNum = 1
        this.lastLeftRPageNum = 1
        this.chooseRIndex = null
      }
      // 搜索的页面不能超过总页数
      if (this.totalPage.r < this.rPar.pageNum) return
      this.axios({
        method: 'post',
        url: Outil.getUrl('/transcriberBooking/listSourceR'),
        data: this.rPar,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then((res) => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          let inputBox = document.getElementsByClassName('device_condition')[0]
          if (inputBox) {
            inputBox.blur()
          }
          this.totalPage.r = res.data.result.pages || 1
          let data = res.data.result.list
          if (this.rPar.pageNum == 1) {
            this[rList] = data
            // 判断是否进入页面第一次调, 如果第一次则给个默认的r
            if (first) this.chooseRIndex = 0
            return
          }
          data.map((v, i) => {
            this[rList].push(v)
          })
        }
      }).catch(() => {})
    },
    // 转换时间
    transformTime (time) {
      let endTime = time + 30
      let start = `${Math.floor(time / 60) > 9 ? '' : '0'}${Math.floor(
        time / 60
      )}:${time % 60 > 9 ? '' : '0'}${time % 60}`
      let end = `${Math.floor(endTime / 60)}:${endTime % 60}`
      this.dateInfo.date.start = start
      this.dateInfo.date.end = end
    },
    // 选择R
    chooseR (item, index) {
      this.chooseRIndex = index
      this.event.rpeerid = item.peerId
      this.event.rname = item.name
      // 更改创建booking中的R info
      this.creatingInfo.update({
        recvs: [item.name],
        rpeerId: item.peerId
      })
    },
    // 更新创建booking头部信息的时间
    upeHeaderCreateBookTime (start, end, id) {
      this.creatingInfo.update({
        mstart: start,
        mend: end,
        start: new Date(start),
        end: new Date(end),
        temId: id,
        firstClick: false
      }) // 更新页面头部左侧创建事件的信息
      this.showCreateBoxInfo() // 显示左侧头部的创建信息
    },
    // show create box info in header
    showCreateBoxInfo () {
      this.creatingObj.source = this.creatingInfo.source
      this.creatingObj.r = this.creatingInfo.recvs.join(', ')
      if (this.creatingInfo.start && this.creatingInfo.end) {
        var startDate = new Date(this.creatingInfo.start)
        var endDate = new Date(this.creatingInfo.end)
        if (startDate.getDate() == endDate.getDate()) {
          var timeStr =
            startDate.getFullYear() +
            '-' +
            (startDate.getMonth() + 1) +
            '-' +
            startDate.getDate() +
            ' ' +
            Outil.timeFormat(startDate.getHours()) +
            ':' +
            Outil.timeFormat(startDate.getMinutes()) +
            ' - ' +
            Outil.timeFormat(endDate.getHours()) +
            ':' +
            Outil.timeFormat(endDate.getMinutes())
        } else {
          timeStr =
            startDate.getFullYear() +
            '-' +
            (startDate.getMonth() + 1) +
            '-' +
            startDate.getDate() +
            ' ' +
            Outil.timeFormat(startDate.getHours()) +
            ':' +
            Outil.timeFormat(startDate.getMinutes()) +
            ' - ' +
            endDate.getFullYear() +
            '-' +
            (endDate.getMonth() + 1) +
            '-' +
            endDate.getDate() +
            ' ' +
            Outil.timeFormat(endDate.getHours()) +
            ':' +
            Outil.timeFormat(endDate.getMinutes())
        }
        this.creatingObj.time = timeStr
      }
      this.creating = true
    },
    // close down list
    closeDownList () {
      this.isShow.showColorOpt = false
    },
    // show color box option
    showColorOpt () {
      this.isShow.showColorOpt = !this.isShow.showColorOpt
      let googleAnalyzeEvent = this.dateInfo.show
        ? 'bookingZoomColorOpen'
        : 'bookingEventColorOpen'
      if (!this.isShow.showColorOpt) return
      bookingAnalyze(googleAnalyzeEvent, '/bk')
    },
    // 选择颜色
    chooseColor (color, val, googleAny) {
      this.selColor = color
      this.event.color = val
      let googleAnalyzeEvent = this.dateInfo.show
        ? 'bookingZoomColorChange'
        : 'bookingEventColorChange'
      if (googleAny === false) {
        return
      }
      bookingAnalyze(googleAnalyzeEvent, '/bk')
    },
    // 创建框显示内容
    showCreateBoxCon (item) {
      if (item == 'Transcriber') {
        this.createBox.height = '2.4rem'
        this.isShow.Transcriber = true
        this.isShow.createBoxRList = false
        this.createBox.top = this.createBox.lastTop
      } else {
        this.createBox.height = '1.9rem'
        this.createBox.lastTop = this.createBox.top
        if (this.createBox.bottom) {
          this.createBox.top = `${
            Number(
              this.createBox.top.slice(0, this.createBox.top.indexOf('rem'))
            ) + 0.5
            }rem`
        }
        this.isShow.Transcriber = false
        this.isShow.createBoxRList = false
      }
    },
    // 设置创建框的位置
    getCreateBoxTop (position) {
      let createEventPos = position || this.getEventPro()
      if (!createEventPos) {
        return
      }
      if ($('.fc-highlight').length > 0) {
        createEventPos.rowIndex = $('.fc-highlight')
          .parents('.fc-row.fc-week.fc-widget-content.fc-rigid')
          .index()
      }
      $('.el-main.scrollbar').scrollTop()
      let calendarScrollTop =
        $('.fc-scroller.fc-time-grid-container').scrollTop() || 0
      if (
        ($('#calendar').fullCalendar('getView').name != 'month' &&
          this.startDate.getHours() >= 12 &&
          (this.endDate.getHours() >= this.startDate.getHours() ||
            this.endDate.getHours() == 0)) ||
        (createEventPos.rowIndex && createEventPos.rowIndex >= 4) ||
        createEventPos.bottom
      ) {
        let boxHeight = 170 // 因为创建框朝下，230为创建框盒子高度-日历头部-三角部分距离
        this.createBox.top =
          (createEventPos.top - calendarScrollTop - boxHeight) / 100
        this.createBox.top =
          this.createBox.top < 0 ? 0 : `${this.createBox.top}rem`
        this.createBox.bottom = true
      } else {
        let boxHeight = 96 // 96为切换视图栏+日历title栏+三角部分距离
        this.createBox.top =
          (createEventPos.top + boxHeight - calendarScrollTop) / 100 + 'rem'
        this.createBox.bottom = false
      }
    },
    // 获取事件的位置
    getEventPro () {
      let view = $('#calendar').fullCalendar('getView')
      let offset, position
      if (view.name == 'month') {
        position = this.jsEventObj
          ? $(this.jsEventObj).offset()
          : $('.fc-highlight-skeleton .fc-highlight').offset()
        // position.top = this.jsEventObj ? position.top - 20 : position.top;
        if (position.left == 0 && position.top == 0) {
          position = $(this.jsEventObj)
            .parent()
            .siblings('.fc-more-cell')
            .offset()
        }
        position.top = position.top - 210
      } else {
        offset = this.jsEventObj
          ? $(this.jsEventObj).offset()
          : $('.fc-time-grid-event.fc-helper').offset()
        position = this.jsEventObj
          ? $(this.jsEventObj).position()
          : $('.fc-time-grid-event.fc-helper').position()
        position.left += offset.left
        if ($(this.jsEventObj).siblings('a.fc-event').length > 0) {
          position.left = offset.left
        }
      }
      return position
    },
    // 初始化创建框
    initCreateBox () {
      this.isShow.Transcriber = true
      this.createBox.height = '2.4rem'
    },
    // 选择R
    selR (itm, index) {
      this.chooseRIndex = index
      this.event.rname = itm.name
      this.event.rpeerid = itm.peerId
      // 更改创建booking中的R info
      this.creatingInfo.update({
        recvs: [itm.name],
        rpeerId: itm.peerId
      })
      this.creatingObj.r = this.creatingInfo.recvs.join(', ')
    },
    // 是否显示创建框内的R列表
    showCreBoxRList () {
      // living状态不能修改R
      if (this.disable.input) return
      this.isShow.createBoxRList = !this.isShow.createBoxRList
    },
    // 保存booking事件
    saveAddBooking () {
      let googleAnalyzeEvent = this.dateInfo.show ? 'bookingZoomCreatedEventSave' : 'bookingCalendarEventEditSave'
      bookingAnalyze(googleAnalyzeEvent, '/bk')
      let param = {
        title: this.event.title.trim(),
        rpeerid: this.event.rpeerid,
        startTime: this.event.startTime,
        endTime: this.event.endTime,
        color: this.event.color,
        cycle: this.event.cycle,
        cycleRule: this.event.cycleRule,
        dayOfWeek: this.event.dayOfWeek,
        cycleEndTime: this.event.cycleEndTime
      }
      if (!param.title.trim() && param.title !== 0) {
        this.$message.error(this.$t('lang.titleEmpty'))
        return
      }
      if (!param.rpeerid) {
        this.$message.error(this.$t('lang.rEmpty'))
        return
      }
      if (!this.addFlag) return
      this.addFlag = false
      let url = '/transcriberBooking/createEvent'
      if (this.event.id) {
        // 不是复制就是编辑
        if (!this.isCopy) {
          param.id = this.event.id
          // 更新事件逻辑
          this.UpdateEventLogic(param)
          return
        }
      }
      // 转时间为当天的23：59：59
      param.cycleEndTime = this.TimedayEnd(param.cycleEndTime)
      this.axios({
        method: 'post',
        url: Outil.getUrl(url),
        data: param,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then((res) => {
        this.addFlag = true
        if (res.status == 200) {
          if (res.data.errorCode == '0x0') {
            this.closeCreateBox() // 关闭弹窗
            // 删除成功后刷新列表
            this.getBookingEventAccodT()
          } else {
            let errorInfo =
                this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
        }
      }).catch(() => {
        this.addFlag = true
      })
    },
    // 更新事件逻辑
    UpdateEventLogic (param) {
      if (
        param.cycle != this.eventDetail.cycle ||
        param.cycleRule != this.eventDetail.cycleRule ||
        (param.cycleEndTime
          ? param.cycleEndTime != this.eventDetail.cycleEndTime
          : false)
      ) {
        this.updateLoopEvent(2)
      } else if (
        param.startTime != this.eventDetail.startTime ||
        param.endTime != this.eventDetail.endTime ||
        param.title != this.eventDetail.title ||
        param.color != this.eventDetail.color
      ) {
        if (param.cycle) {
          this.TipType = {
            type: 'LoopEvent',
            Operation: 'update'
          }
          this.TipIsShow = true
          this.addFlag = true
        } else {
          this.updateLoopEvent(0)
        }
      } else {
        this.updateLoopEvent(0)
        this.addFlag = true
      }
    },
    // 更新事件
    updateLoopEvent (item) {
      let param = JSON.parse(JSON.stringify(this.event))
      param.updateRule = item
      // 转时间为当天的23：59：59
      param.cycleEndTime = this.TimedayEnd(param.cycleEndTime)
      this.axios({
        method: 'post',
        url: Outil.getUrl('/transcriberBooking/updateEvent'),
        data: param,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then((res) => {
        this.addFlag = true
        if (res.status == 200) {
          if (res.data.errorCode == '0x0') {
            this.closeCreateBox() // 关闭弹窗
            // 删除成功后刷新列表
            this.getBookingEventAccodT()
          } else {
            let errorInfo =
                this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
            this.$message.error(errorInfo)
          }
        }
      }).catch(() => {
        this.addFlag = true
      })
    },
    // 转时间到当天最后一刻
    TimedayEnd (time) {
      if (time != null && time != 0) {
        let hour = typeof time == 'object' ? time.getHours() : new Date(time).getHours()
        let millisecond = typeof time == 'object' ? time.getTime() : time
        return hour < 23 ? millisecond + (24 * 60 * 60 * 1000 - 1000) : millisecond
      } else {
        return null
      }
    },
    // 跳转日期
    changeDateView (date) {
      $('#calendar').fullCalendar('gotoDate', date)
    },
    // 获取与T相关的预约事件
    getBookingEventAccodT (tId) {
      if (this.chooseRIndex == null) return
      this.loadingCalendar = true
      var param = {
        rids: [this.RPitchMessage.peerId],
        startDate: 0,
        endDate: '15015547523410',
        booking: true
      }
      this.axios({
        method: 'post',
        url: Outil.getUrl('/transcriberBooking/listEventByReceiverId'),
        data: param,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then((res) => {
        this.loadingCalendar = false
        $('#calendar').fullCalendar('removeEvents') // 先清空表格
        this.events = []
        let eventDatas = []
        if (res.status != 200 || res.data.errorCode != '0x0') {
          this.$message(this.$t('lang.'))
          return
        }
        let data = res.data.result
        data.map((item, idx) => {
          if (item.liveStatus < 2 || item.liveStatus == 5) {
            // 过去的事件不再执行
            let eventId =
                'id=' +
                item.id +
                '&tname=' +
                item.tname +
                '&rname=' +
                item.rname +
                '&tpeerid=' +
                item.tpeerid +
                '&rpeerid=' +
                item.rpeerid +
                '&type=' +
                item.ttype +
                '&cycle=' +
                item.cycle +
                '&cycleRule=' +
                item.cycleRule +
                '&dayOfWeek=' +
                item.dayOfWeek +
                '&cycleEndTime=' +
                item.cycleEndTime
            let bgColor = item.color || 'rgb(15, 190, 113)'
            let colorName
            switch (bgColor) {
              case 'rgb(255, 192, 0)':
                colorName = 'yellow'
                break
              case 'rgb(70, 201, 225)':
                colorName = 'skyblue'
                break
              case 'rgb(47, 141, 244)':
                colorName = 'blue'
                break
              case 'rgb(255, 197, 115)':
                colorName = 'orange'
                break
              case 'rgb(255, 106, 146)':
                colorName = 'pink'
                break
              default:
                colorName = 'green'
                bgColor = 'rgb(15, 190, 113)'
                break
            }
            let startTime = Outil.getDateTime(new Date(item.startTime))
            let endTime = Outil.getDateTime(new Date(item.endTime))
            let eventData = {
              id: eventId,
              title: `${startTime.hour}:${startTime.min}:${startTime.s}-${endTime.hour}:${endTime.min}:${endTime.s} ${item.title}`,
              start: $.fullCalendar.moment(item.startTime),
              end: $.fullCalendar.moment(item.endTime),
              backgroundColor: bgColor,
              colorName: colorName,
              className: `livestatus-${item.liveStatus}`,
              borderColor: 'transparent'
            }
            eventDatas.push(eventData)
            $('#calendar').fullCalendar('renderEvent', eventData, true) // 渲染到表格
          }
        })
        this.events = eventDatas
        this.addCopyIcon()
        $('.fc-more').click(() => {
          this.addCopyIcon('.fc-popover.fc-more-popover')
        })
      }).catch(() => {
        this.loadingCalendar = false
      })
    },
    // 添加copy icon
    addCopyIcon (fatherEle) {
      fatherEle = fatherEle || ''

      // 添加cc图标
      $(`${fatherEle} .fc-widget-content a.fc-event .fc-content`).append(
        `<span class='iconWrap  fr'>
        <i class='iconfont icon-CC'></i>
        </span>`
      )
    },
    // 删除booking
    deleteEvent () {
      bookingAnalyze('bookingCalendarEventDelete', '/bk')
      this.TipType = {
        type: this.eventDetail.cycle ? 'LoopEvent' : 'noLoopEvent',
        Operation: 'delete'
      }
      this.TipIsShow = true
    },
    // 删除事件
    deleteLoopEvent (item) {
      let transcriberEvents = [
        { id: this.deleteId, cycle: !!item, deleteRule: item }
      ]
      this.axios({
        method: 'post',
        url: Outil.getUrl('/transcriberBooking/deleteEvent'),
        data: { transcriberEvents: transcriberEvents },
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then((res) => {
        if (res.data.errorCode == '0x0') {
          this.$message({
            message: this.$t('lang.success'),
            type: 'success'
          })
          this.isShow.createBox = false
          // 删除成功后刷新列表
          this.getBookingEventAccodT()
        } else {
          let errorInfo =
            this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
          this.$message.error(errorInfo)
        }
      }).catch(() => {})
    },
    // 小屏下切换r列表是否显示
    toggleR (e) {
      if (document.body.clientWidth > 1024) {
        return
      }
      e.stopPropagation()
      this.isShow.rList = !this.isShow.rList
    }
  },
  computed: {
    // 日历渲染
    eventSources () {
      const self = this
      return [
        {
          events (start, end, timezone, callback) {
            self.$nextTick(() => {
              callback(self.events.filter((v) => { }))
            })
          }
        }
      ]
    },
    // 计算r列表高度
    rListBoxHeight () {
      if (this.calendar.searchR) {
        return 'calc(100% - 100px)'
      }
      return 'calc(100% - 50px)'
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>
<style lang='less' scoped>
@import '~fullcalendar/dist/fullcalendar.css';
::v-deep ::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::v-deep ::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background-color: rgb(233, 233, 233);
  opacity: 0.7;
}
::v-deep ::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 5px;
  background: none;
}
::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0; /*高宽分别对应横竖滚动条的尺寸*/
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background-color: rgb(233, 233, 233);
  opacity: 0.7;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 5px;
  background: none;
}
input {
  outline: none;
}
.show_height {
  height: 170px;
}
ul {
  margin-bottom: 0;
}
.green {
  border: 1px solid #0a9a5a;
  background: #0fbe71;
}
.skyblue {
  background-color: #46c9e1;
  border: 1px solid #0ca595;
}
.yellow {
  background-color: rgb(255, 192, 0);
  border: 1px solid rgb(255, 192, 0);
}
.blue {
  background-color: #2f8df4;
  border: 1px solid #126ed3;
}
.orange {
  background-color: #ffc573;
  border: 1px solid #d8a966;
}
.pink {
  background-color: #ff6a92;
  border: 1px solid #ee3656;
}
.calendar {
  // height: 100%;
  height: 850px;
  .gray {
    color: #666 !important;
    input {
      color: #666 !important;
    }
  }
  .device_list {
    position: absolute;
    // width: 520px;
    width: 300px;
    height: 100%;
    float: left;
    // margin-bottom: 30px;
    background: #444;
    .r_title {
      padding-right: 30px;
    }
    .create_info {
      width: calc(100% - 35px);
      font-size: 10px;
      text-align: left;
      height: 50px;
      margin-left: 35px;
      line-height: 48px;
      .iconfont {
        font-size: 15px;
      }
      .title_text {
        margin: 0 5px;
        color: #999;
      }
      .create_info_cont {
        width: calc(100% - 75px);
        span {
          width: 30%;
          padding: 0 5px;
          line-height: 50px;
        }
      }
    }
    .device_content {
      height: calc(100% - 50px);
      border-bottom: 1px solid #000;
      border-top: 1px solid #000;
    }
    .source_list,
    .r_list {
      width: 100%;
      height: 100%;
      border-right: 1px solid #000;
      font-size: 18px;
      text-align: left;
      box-sizing: border-box;
      float: left;
    }
    .device_title {
      height: 50px;
      line-height: 50px;
      background-color: #555;
      padding-left: 20px;
      padding-right: 10px;
      i {
        font-size: 23px;
      }
      .icon-Filter {
        font-size: 20px;
        margin: 0 10px;
        padding-left: 10px;
      }
    }
    .search_ipt {
      height: 50px;
      line-height: 50px;
      background: #aaa;
      color: #eee;
      outline: none;
      input {
        height: 50px;
        background: transparent;
        border: none;
        width: 80%;
        padding-left: 14px;
        font-size: 16px;
      }
      i {
        font-size: 23px;
        margin-right: 5%;
      }
      i.down_arrow {
        display: none;
      }
    }
    .filter_box {
      height: 360px;
      background: #aaa;
      padding: 10px 0;
      ::v-deep .el-checkbox .el-checkbox__inner {
        background: #fff;
      }
      .filter_item {
        margin: 0 auto;
        width: 90%;
        height: unset;
        background: #444;
        border-radius: 5px;
        .item_title {
          border-radius: 5px;
          height: 45px;
          line-height: 45px;
          padding-left: 10px;
          background: #616161;
          color: #aaa;
          font-size: 14px;
        }
        .item_title.active {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          // .el-icon-caret-bottom {
          //     transform: rotate(-180deg);
          // }
        }
        // .pull_down {
        //     position: absolute;
        //     right: 15px;
        //     top: 50%;
        //     transform: translateY(-50%);
        // }
        .el-icon-caret-bottom {
          color: #fff;
          font-size: 22px;
          line-height: 45px;
          margin-right: 15px;
          transition: 0.3s;
        }
      }
      .item_content {
        margin: 0 auto;
        width: 90%;
        height: 235px;
        line-height: 30px;
        background-color: #444;
        // margin-bottom: 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 12px;
      }
    }
    ::v-deep .list_box {
      // font-size: 16px;
      // li {
      //     height: 50px;
      //     line-height: 50px;
      //     padding-left: 24px;
      //     max-width: 250px;
      //     box-sizing: content-box;
      //     span {
      //         position: relative;
      //         z-index: 1;
      //     }
      // }
      .antline {
        position: relative;
      }
    }
  }
  // .magnifying {
  //     float: left;
  //     width: 80px;
  //     height: calc(100% - 50px);
  //     position: absolute;
  //     left: 520px;
  //     top: 50px;
  //     border-top: 1px solid #000;
  //     .body {
  //         width: 100%;
  //         height: calc(100% - 20px);
  //         overflow-y: scroll;
  //     }
  //     .mnf_btn {
  //         width: 100%;
  //         height: 22px;
  //         border-bottom: 1px solid #333;
  //     }
  //     .mnf_btn:nth-child(2n-1) {
  //         border-bottom: 1px dotted #333;
  //     }
  //     button {
  //         width: 55px;
  //         height: 20px;
  //         border-radius: 3px;
  //         background: #333;
  //         border: 1px solid #444;
  //         font-size: 12px;
  //     }
  // }
  .calendar_box {
    position: absolute;
    right: 0px;
    left: 300px;
    height: 100%;
    background: #444;
    z-index: 1;
    // ::v-deep .fc-unthemed th, .fc-unthemed td, .fc-unthemed thead,
    // ::v-deep .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row,
    // ::v-deep .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view,
    // ::v-deep .fc-unthemed .fc-list-heading td {
    //     border-color: #333;
    // }
    ::v-deep .fc-more-popover {
      max-height: 200px;
      overflow: scroll;
    }
    ::v-deep .fc-unthemed {
      th,
      td,
      thead,
      tbody,
      .fc-divider,
      .fc-row,
      .fc-content,
      .fc-popover,
      .fc-list-view,
      .fc-list-heading td {
        border-color: #333;
      }
    }
    ::v-deep .fc-toolbar.fc-header-toolbar {
      padding-right: 80px;
      margin-bottom: 0;
      height: 50px;
    }
    ::v-deep .fc-unthemed .fc-head-container.fc-widget-header {
      border-top-color: #000;
    }
    ::v-deep .fc-right .fc-button-group {
      width: 230px;
      height: 28px;
      line-height: 28px;
      border-radius: 4px;
      overflow: hidden;
      background-color: #666;
      text-align: center;
      cursor: pointer;
      margin-top: 10px;
      button {
        float: left;
        width: 33.3%;
        height: 100%;
        border-right: 1px solid #999;
        background: #666;
        color: #fff;
        border: none;
        text-shadow: none;
      }
      .fc-state-active {
        background: #999;
        border-top: none;
      }
    }
    ::v-deep .fc-toolbar.fc-header-toolbar {
      padding-right: 80px;
      .fc-center {
        position: relative;
        width: 250px;
        > div {
          width: 100%;
          h2 {
            text-align: center;
          }
          button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: #444;
            background-image: none;
            box-shadow: none;
            text-shadow: none;
            border: none;
            .fc-icon {
              font-size: 17px;
              color: #fff;
              font-weight: normal;
            }
          }
          .fc-prev-button {
            left: 0;
          }
          .fc-next-button {
            right: 0;
          }
        }
      }
    }
    .fc-event,
    .fc-event-dot {
      background-color: rgb(15, 190, 113);
      border: none;
    }
    .fc-time-grid-event.fc-short .fc-time span {
      display: inline;
    }
    .fc-time-grid-event.fc-short .fc-time::before,
    .fc-time-grid-event.fc-short .fc-time::after {
      content: '';
    }
  }
  ::v-deep .create_booking {
    position: absolute;
    width: 4rem;
    height: 3rem;
    // background-color: rgba(201, 201, 201, 1);
    background-color: #dcdcdc;
    border-radius: 4px;
    z-index: 2030;
    padding: 0.16rem;
    padding-right: 0.2rem;
    color: #999;
    font-size: 0.16rem;
    box-sizing: border-box;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.78);
    .create_title {
      margin-bottom: 15px;
      .separator {
        padding: 0 10px;
      }
      li.active {
        color: #000;
      }
      .event_color {
        width: 20px;
        height: 20px;
        // background: rgb(15, 190, 113);
        margin-right: 10px;
        border: none;
      }
      .event_title {
        text-align: left;
        font-size: 16px;
        color: #000;
        width: 80%;
      }
    }
    .event_content_detail {
      font-size: 12px;
      color: #000;
      .detail_item {
        height: 32px;
        line-height: 32px;
        border-bottom: 1px solid rgba(153, 153, 153, 1);
        padding: 0 10px;
        margin-bottom: 0;
        span:first-child {
          color: #666;
        }
        span:nth-child(2) {
          width: 80%;
          text-align: right;
        }
      }
      margin-bottom: 30px;
    }
    .c_item {
      height: 30px;
      margin-bottom: 10px;
      input {
        width: 310px;
        height: 30px;
        background-color: rgba(228, 228, 228, 1);
        color: #000;
        border: 1px solid rgba(153, 153, 153, 1);
        border-radius: 4px;
        text-indent: 10px;
        font-size: 12px;
        box-sizing: border-box;
      }
      .time_opt {
        width: 170px;
        height: 30px;
        line-height: 30px;
        color: #000;
        background: rgba(228, 228, 228, 1);
        border: 1px solid rgba(153, 153, 153, 1);
        border-radius: 4px;
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 100%;
        }
        input.el-input__inner {
          width: 100%;
          background: transparent;
          padding: 0;
          border: none;
        }
        .el-input__prefix,
        .el-input__suffix-inner {
          display: none;
        }
      }
      .LoopWrap {
        display: flex;
        justify-content: space-between;
        .LoopSelect,
        .EndTime {
          width: 170px;
          height: 30px;
          background-color: #e4e4e4;
          .el-input__inner {
            width: 170px;
            height: 30px;
            color: #252525;
            padding: 0;
            background-color: transparent;
          }
          .el-input__inner::-webkit-input-placeholder {
            color: #aaaaaa;
          }
          i {
            color: #999999;
            font-size: 20px;
          }
        }
        .EndTime {
          .el-input__prefix {
            width: 50px;
            position: absolute;
            left: 126px;
            top: 0;
            font-size: 18px;
          }
          .el-input__suffix {
            display: none;
          }
        }
        .LoopSelect {
          .el-input__inner {
            padding-right: 26px;
          }
        }
        .el-picker-panel.el-date-picker.el-popper.pick_date_time {
          z-index: 2039 !important;
        }
        .el-picker-panel.el-date-picker.el-popper {
          z-index: 2039 !important;
        }
        .el-scrollbar__wrap {
          .el-scrollbar__view {
            background-color: #e4e4e4;

            .el-select-dropdown__item {
              color: #252525;
              text-align: left;
            }
            .hover {
              background-color: #e4e4e4;
              color: #33ab4f;
            }
          }
        }
      }
    }
    .des.c_item {
      height: 60px;
    }
    .Transcriber {
      .color_box {
        height: 100%;
        padding-right: 22px;
      }
      .color_opt {
        width: 220px;
        height: 40px;
        background: #fff;
        color: #999;
        border-radius: 4px;
        position: absolute;
        right: -11px;
        top: 31px;
        padding: 10px 0;
        padding-left: 6px;
        font-size: 12px;
        line-height: 20px;
        z-index: 10000;
        li {
          width: 20px;
          height: 20px;
          cursor: pointer;
          margin-left: 10px;
        }
      }
      .color_opt.triangle::before {
        left: 191px;
      }
      .color {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-top: 4px;
        box-sizing: content-box;
      }
      .el-icon-caret-bottom {
        font-size: 20px;
        position: absolute;
        top: 16%;
        right: 0px;
        transform: translateY(-50%);
        transform: rotate(0deg);
        transition: 0.3s;
      }
      .el-icon-caret-bottom.top {
        transform: rotate(-180deg);
      }
      .to {
        color: #000;
        margin-left: 7px;
        line-height: 30px;
        font-size: 12px;
      }
      .reporter,
      textarea {
        width: 367px;
        border: 1px solid rgba(153, 153, 153, 1);
      }
      textarea {
        background: rgba(228, 228, 228, 1);
        border-radius: 4px;
        height: 60px;
        color: #000;
        font-size: 12px;
        line-height: 15.6px;
        padding: 2px 2px 2px 10px;
        box-sizing: border-box;
      }
    }
    .create_footer {
      margin-top: 20px;
      button {
        width: 86px;
        height: 30px;
        background: #999;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 12px;
      }
      .save,
      .edit {
        background-color: #0c6;
        margin-left: 10px;
      }
      .delete {
        width: 72px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid rgba(153, 153, 153, 1);
        background: transparent;
        color: #333;
      }
      .copy {
        margin-left: 100px;
        color: #39aa50;
        border: 1px solid #39aa50;
        background: transparent;
      }
      .end_live {
        background: #f00;
        color: #fff;
      }
    }
    .device {
      color: rgb(102, 102, 102);
      margin: 0 9px;
      .d_item {
        width: 100%;
        height: 32px;
        line-height: 32px;
        border-bottom: 1px solid rgba(153, 153, 153, 1);
        font-size: 12px;
        padding: 0 20px 0 10px;
        .val {
          color: #000;
          width: 60%;
          text-align: right;
        }
        .delay,
        .bitrate {
          float: right;
          height: 25px;
          width: 135px;
          margin-top: 3px;
          margin-right: -20px;
          border: none;
          padding-left: 10px;
          padding-right: 25px;
          box-sizing: border-box;
          color: #333;
          background-color: transparent;
          text-align: right;
        }
        .delay.active,
        .bitrate.active {
          text-align: left;
          background-color: #fff;
        }
        .triangle:before {
          top: 60%;
          left: 95%;
          transform: translateY(-50%);
          border-color: transparent;
          border-top-color: #999;
          border-width: 6px;
          transition: 0.3s;
        }
        .triangle.up:before {
          top: 23%;
          transform: rotate(-180deg);
        }
        .createbox_r_list {
          position: absolute;
          right: 0;
          top: 32px;
          background: #ababab;
          width: 2.2rem;
          height: 170px;
          text-align: left;
          li {
            padding-left: 10px;
            height: 32px;
            line-height: 32px;
            box-sizing: border-box;
            font-size: 12px;
          }
          li:hover {
            background: rgba(0, 208, 105, 0.2);
          }
        }
      }
      .d_item.source {
        input {
          float: right;
          border: none;
          height: 25px;
          width: 100px;
          padding-left: 10px;
          padding-right: 25px;
          margin-right: -20px;
          margin-top: 3px;
          box-sizing: content-box;
          color: #000;
        }
      }
    }
  }
  ::v-deep .create_booking.triangle:before {
    border-top-color: #dcdcdc;
    border-right-color: #dcdcdc;
    border-bottom-color: transparent;
    left: 0.6rem;
    top: -0.095rem;
    border-width: 12px;
    box-shadow: 3px -3px 3px rgba(0, 0, 0, 0.38);
    transform: rotate(-45deg);
  }
  ::v-deep .create_booking.right.triangle:before {
    left: 3.4rem;
  }
  ::v-deep .create_booking.bottom.triangle:before {
    top: 2.26rem;
    // border-color: transparent;
    // border-bottom-color: #dcdcdc;
    // border-lrft-color: #dcdcdc;
    transform: rotate(135deg);
  }
  ::v-deep .create_booking.bottom.device.triangle:before {
    top: 1.76rem;
  }
  ::v-deep .el-checkbox .el-checkbox__inner {
    background: transparent;
  }
  ::v-deep .el-checkbox .el-checkbox__inner::after {
    border-color: #33ab4f;
    left: 4px;
    top: 0;
  }
  ::v-deep .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #fff;
    border-color: transparent;
  }
  ::v-deep .el-checkbox__inner:hover {
    border-color: #fff;
  }
  ::v-deep .el-checkbox__input.is_focus {
    border-color: #fff;
  }
  ::v-deep.el-checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
    font-size: 12px;
  }
  ::v-deep .el-checkbox .el-checkbox__inner {
    width: 15px;
    height: 15px;
  }
  ::v-deep
    .el-checkbox
    .el-checkbox__input.is-indeterminate
    .el-checkbox__inner {
    border-color: transparent;
  }
  ::v-deep .el-checkbox__label {
    font-size: 12px;
  }
  ::v-deep .el-checkbox {
    display: block;
    margin: 0;
    padding-left: 8px;
    height: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  ::v-deep .el-checkbox:hover {
    background-color: rgba(57, 170, 80, 0.25);
  }
  ::v-deep .item_content.pointer div {
    margin: 0 !important;
  }
}
::v-deep .cal_date_opt {
  margin-left: 40%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  i {
    font-size: 18px;
  }
  .el-input__suffix,
  .el-input__prefix {
    display: none;
  }
  .el-date-editor input {
    color: transparent;
  }
}
::v-deep .replace_title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  font-size: 16px;
  color: #fff;
}
::v-deep .el-input__inner {
  background-color: transparent;
  z-index: 1000;
  position: relative;
  color: transparent;
  cursor: pointer;
}
::v-deep #calendar .fc-center {
  visibility: hidden;
  display: none;
}
::v-deep #calendar .fc-header-toolbar {
  margin-bottom: 0;
}
::v-deep .fc-time-grid-event.fc-helper {
  overflow: visible;
}
::v-deep .el-popper[x-placement^='top'] .popper__arrow:after {
  background: #fff;
}
::v-deep .calendar .calendar_box .fc-unthemed th {
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  background: #555;
  color: #fff;
  font: 400 normal 14px/50px 'Microsoft yahei';
}
::v-deep .fc-axis.fc-widget-header,
::v-deep .fc-axis.fc-time.fc-widget-content {
  width: 39px !important;
  box-sizing: border-box;
}
::v-deep .fc-unthemed td.fc-today {
  background-color: #666;
}
::v-deep .fc-axis.fc-time.fc-widget-content span {
  font-size: 12px;
}
::v-deep .antline {
  // position: relative;
  background-color: #3f5d46 !important;
  padding: 2px;
  background-image: -webkit-linear-gradient(
    -45deg,
    rgba(16, 180, 103, 1) 25%,
    rgba(16, 180, 103, 0) 25%,
    rgba(16, 180, 103, 0) 50%,
    rgba(16, 180, 103, 1) 50%,
    rgba(16, 180, 103, 1) 75%,
    rgba(16, 180, 103, 0) 75%,
    rgba(16, 180, 103, 0)
  );
  background-size: 10px 10px;
  animation: antline 0.8s linear infinite;
  box-sizing: content-box;
}
::v-deep .fc-widget-content a.fc-event .fc-content .fc-title {
  width: 80%;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
::v-deep .antline::after {
  position: absolute;
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  display: block;
  content: '';
  background-color: #3f5d46;
}
@keyframes antline {
  0% {
    background-position: 0px 0px;
  }
  50% {
    background-position: 0px 5px;
  }
  100% {
    background-position: 0px 10px;
  }
}
@media (max-width: 1660px) {
  ::v-deep .fc-widget-content a.fc-event .fc-content .fc-title {
    width: 80%;
  }
}
@media (max-width: 1415px) {
  .calendar_box .cal_date_opt {
    margin-left: 20%;
  }
}
@media (max-width: 1320px) {
  .calendar.pr .device_list {
    width: 450px;
  }
  // .calendar.pr .magnifying {
  //     width: 50px;
  //     left: 450px;
  //     button {
  //         width: 45px;
  //     }
  // }
  .calendar.pr .calendar_box {
    left: 450px;
  }
}
@media (max-width: 1215px) {
  ::v-deep .fc-widget-content a.fc-event .fc-content .fc-title {
    width: 70%;
  }
  .calendar_box .cal_date_opt {
    margin-left: 10%;
  }
}
@media (max-width: 1140px) {
  .calendar .calendar_box {
    ::v-deep .fc-toolbar.fc-header-toolbar {
      padding-right: 20px;
    }
  }
}
@media (max-width: 1070px) {
  .calendar.pr .device_list {
    width: 350px;
  }
  // .calendar.pr .magnifying {
  //     width: 50px;
  //     left: 350px;
  //     button {
  //         width: 45px;
  //     }
  // }
  .calendar.pr .calendar_box {
    left: 350px;
  }
}
@media (max-width: 1024px) {
  ::v-deep #Booking .header .table_view {
    margin-left: -3px;
  }
  @headerLeft: 30px;
  .calendar.pr {
    .device_list {
      float: none;
      height: 100px;
      width: 100%;
      .create_info {
        display: none;
      }
      .device_content {
        height: 100px;
      }
      .source_list {
        border: none;
        margin: 15px 0;
        height: 70%;
        border-right: 1px solid #555;
        .device_title {
          height: 35px;
          line-height: inherit;
        }
      }
      .search_ipt {
        width: 32%;
        height: 32px;
        line-height: 32px;
        position: absolute;
        left: @headerLeft;
        border-radius: 5px;
        background: #555;
        outline: none;
        input {
          width: 68%;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          padding-left: 0;
          color: #fff;
        }
        i.icon-zoom {
          float: left;
          margin-left: 5%;
          margin-top: 2px;
          font-size: 16px;
        }
        i.down_arrow {
          display: block;
          float: right;
          margin-right: 3%;
          margin-top: 11px;
          border-top-color: #d8d8d8;
        }
      }
      .device_title {
        width: calc(64% + 30px);
        padding-left: @headerLeft;
        background: #444;
        > .icon-zoom {
          display: none;
        }
        .icon-Filter {
          margin-right: -10px;
        }
      }
      .filter_box {
        position: relative;
        z-index: 1008;
        width: 64%;
        margin-left: @headerLeft;
      }
      .list_box {
        position: absolute;
        top: 87px;
        left: 30px;
        width: 32%;
        height: 300px !important;
        z-index: 1500;
        background: #555;
        border-radius: 7px;
        ::v-deep ul {
          border-radius: 7px;
        }
      }
      .r_list {
        .search_ipt {
          left: calc(50% + @headerLeft);
        }
        .list_box {
          left: calc(50% + 30px);
        }
      }
    }
    // .magnifying {
    //     left: 0;
    //     top: 150px;
    //     height: calc(100% - 70px);
    //     background: #444;
    // }
    .calendar_box {
      left: 50px;
      top: 100px;
      height: calc(100% - 20px);
      .cal_date_opt {
        margin-left: @headerLeft;
      }
    }
  }
}
@media (max-width: 550px) {
  .calendar_box .cal_date_opt {
    ::v-deep .el-date-editor.el-input {
      width: 170px;
    }
    ::v-deep .el-input__inner {
      padding: 0;
    }
  }
}
::v-deep .fc-time-grid-event.fc-v-event.fc-event .fc-content .fc-time,
::v-deep .fc-day-grid-event.fc-h-event.fc-event .fc-content .fc-time {
  display: none;
}
::v-deep
  .fc-time-grid-event.fc-v-event.fc-event.fc-helper.antline
  .fc-content
  .fc-time {
  display: block;
}
::v-deep .fc-widget-content .fc-content .iconWrap {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  // align-items: center;
  margin-right: 3px;
  background: #bbbbbb;
}
::v-deep .fc-widget-content .fc-content .icon-CC {
  font-size: 12px;
  transform: scale(0.6);
  color: #252525;
  // display: none;
}
::v-deep .fc-widget-content .fc-content:hover .icon-CC {
  // display: block;
}
::v-deep a.fc-event.fc-start.fc-end.fc-draggable {
  min-height: 1px;
  .fc-title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
}
::v-deep .fc-popover .fc-header {
  color: #333;
}
</style>
